import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { connect } from 'services/store'
import intl from 'services/intl'
import { Field } from 'uic'

const LostPasswordStep2 = ({ submitTempPasswordForm, isPendingTempPassword, goToLoginScreen }) => {
  const [birthDay, setBirthDay] = useState('')
  const [birthMonth, setBirthMonth] = useState('')
  const [birthYear, setBirthYear] = useState('')
  const [birthdateErrorMessage, setBirthdateErrorMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [userIdErrorMessage, setUserIdErrorMessage] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState('')
  const [iban, setIban] = useState('')
  const [ibanErrorMessage, setIbanErrorMessage] = useState('')

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    const SITE_KEY = process.env.REACT_APP_SITE_KEY
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        // console.log('Script loaded!')
      }
    )
  }, [])

  const onBirthChange = (e, type) => {
    const newValue = e.target.value.replace(/[^0-9\s]/g, '')
    if (type === 'birthDay') {
      setBirthDay(newValue)
    } else if (type === 'birthMonth') {
      setBirthMonth(newValue)
    } else {
      setBirthYear(newValue)
    }
    setBirthdateErrorMessage('')
  }

  const onUserIdChange = (e) => {
    setUserId(e.target.value)
    setUserIdErrorMessage('')
  }

  const onZipCodeChange = (e) => {
    setZipCode(e.target.value)
    setZipCodeErrorMessage('')
  }

  const onIbanChange = (e) => {
    setIban(e.target.value)
    setIbanErrorMessage('')
  }

  const submitForm = (e) => {
    e.preventDefault()
    const formatedBirthDay = birthDay.length === 1 ? '0' + birthDay : birthDay
    const formatedBirthMonth = birthMonth.length === 1 ? '0' + birthMonth : birthMonth

    // check birthdate
    const birthDate = `${formatedBirthDay}/${formatedBirthMonth}/${birthYear}`
    const birth = dayjs(birthDate, 'DD/MM/YYYY')

    if (!dayjs(birthDate, 'DD/MM/YYYY', true).isValid() || dayjs().diff(birth) < 0) {
      setBirthdateErrorMessage(intl('subscription_minor_form_2_birthday_error_1'))
      return
    }

    if (userId === '') {
      setUserIdErrorMessage(intl('error_required'))
      return
    }
    if (zipCode === '') {
      setZipCodeErrorMessage(intl('error_required'))
      return
    }
    if (iban === '') {
      setIbanErrorMessage(intl('error_required'))
      return
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_SITE_KEY, { action: 'submit' })
        .then((token) => {
          submitTempPasswordForm({
            recaptcha: token,
            birthDate,
            userId,
            zipCode,
            iban,
          })
        })
    })
  }

  return (
    <div className='stepComponent step2'>
      <div className='stepComponent-wrapper'>
        <div className='stepComponent-content'>
          <h2 className='stepComponent-title'>{intl`forgotten_password_step_2_title`}</h2>
          <form className='stepComponent-form'>
            <div className='fieldsWrapper'>
              <span className='mentions sr-only'>{intl`login_text_3`}</span>
              <Field fieldId='minorBirthDay' className='field floatLabelField'>
                <fieldset>
                  <legend className='fieldset-legend'>{intl`forgotten_password_step_2_placeholder_1`}</legend>
                  <input
                    className={`psa-inputText psa-inputText-short ${birthDay ? 'filled' : ''}`}
                    type='text'
                    name='minorBirthDay'
                    id='minorBirthDay'
                    value={birthDay}
                    data-type='integer'
                    data-required
                    aria-required='true'
                    onChange={(e) => {
                      onBirthChange(e, 'birthDay')
                    }}
                  />
                  <label htmlFor='minorBirthDay'>{intl`subscription_minor_form_2_placeholder_1`}</label>
                  <input
                    className={`psa-inputText psa-inputText-short ${birthMonth ? 'filled' : ''}`}
                    type='text'
                    name='minorBirthMonth'
                    id='minorBirthMonth'
                    value={birthMonth}
                    data-type='integer'
                    data-required
                    aria-required='true'
                    onChange={(e) => {
                      onBirthChange(e, 'birthMonth')
                    }}
                  />
                  <label htmlFor='minorBirthMonth'>{intl`subscription_minor_form_2_placeholder_2`}</label>
                  <input
                    className={`psa-inputText psa-inputText-short ${birthYear ? 'filled' : ''}`}
                    type='text'
                    name='minorBirthYear'
                    id='minorBirthYear'
                    value={birthYear}
                    data-type='integer'
                    data-required
                    aria-required='true'
                    onChange={(e) => {
                      onBirthChange(e, 'birthYear')
                    }}
                  />
                  <label htmlFor='minorBirthYear'>{intl`subscription_minor_form_2_placeholder_3`}</label>
                </fieldset>
                {birthdateErrorMessage !== '' && (
                  <p className='stepComponent-form-error'>{birthdateErrorMessage}</p>
                )}
              </Field>

              <Field fieldId='userId' className='field floatLabelField'>
                <input
                  className={`psa-inputText ${userId ? 'filled' : ''}`}
                  type='text'
                  name='userId'
                  id='userId'
                  value={userId}
                  onChange={onUserIdChange}
                  data-required
                  aria-required='true'
                />
                <label htmlFor='userId'>{intl`forgotten_password_step_2_placeholder_2`}*</label>
                {userIdErrorMessage !== '' && (
                  <p className='stepComponent-form-error'>{userIdErrorMessage}</p>
                )}
              </Field>

              <Field fieldId='zipCode' className='field floatLabelField'>
                <input
                  className={`psa-inputText ${zipCode ? 'filled' : ''}`}
                  type='text'
                  name='zipCode'
                  id='zipCode'
                  value={zipCode}
                  onChange={onZipCodeChange}
                  data-required
                  aria-required='true'
                />
                <label htmlFor='zipCode'>{intl`forgotten_password_step_2_placeholder_3`}*</label>
                {zipCodeErrorMessage !== '' && (
                <p className='stepComponent-form-error'>{zipCodeErrorMessage}</p>
              )}
              </Field>
              
              <Field fieldId='iban' className='field floatLabelField'>
                <input
                  className={`psa-inputText ${iban ? 'filled' : ''}`}
                  type='text'
                  name='iban'
                  id='iban'
                  value={iban}
                  onChange={onIbanChange}
                  data-required
                  aria-required='true'
                />
                <label htmlFor='iban'>{intl`forgotten_password_step_2_placeholder_4`}**</label>
                {ibanErrorMessage !== '' && (
                <p className='stepComponent-form-error'>{ibanErrorMessage}</p>
              )}
              </Field>
              
            </div>
            <p className='stepComponent-mentions'>{intl`forgotten_password_step_2_txt_1`}</p>
            <p className='stepComponent-mentions'>
              **{intl`forgotten_password_step_2_placeholder_5`}
            </p>
          </form>
        </div>
        <div className='stepComponent-actions'>
          <button
            type='button'
            className='stepComponent-action stepComponent-action-secondary'
            onClick={goToLoginScreen}>
            {intl`forgotten_password_step_2_cta_2`}
          </button>
          <button
            type='submit'
            className='stepComponent-action stepComponent-action-ternary'
            onClick={submitForm}
            disabled={isPendingTempPassword}>
            {intl`forgotten_password_step_2_cta_1`}
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({
  view: { submitTempPasswordForm, isPendingTempPassword, goToLoginScreen },
}) => {
  return {
    submitTempPasswordForm,
    isPendingTempPassword,
    goToLoginScreen,
  }
}

export default connect(mapStateToProps)(LostPasswordStep2)
