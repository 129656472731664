import React, { useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'services/store'
import utils from 'services/utils'
import { updateCatFlashSettings, updateCatGreenSettings } from 'services/store/actions'
import BORequester from 'services/api/BORequester'
import routes from 'routes'
import { Header, Footer, SideNav } from 'uic'
import { getClientNumber } from 'services/misc'
import styled, { CSS_VARS } from 'services/styled'

export const PageTitle = ({ children, hide = false, height = null }) => {
  const pageTitleElement = document.getElementById('MainTemplate-pageTitle')

  useEffect(() => {
    if (pageTitleElement) {
      if (hide) {
        pageTitleElement.style.display = 'none'
        return () => {
          pageTitleElement.style.display = 'block'
        }
      } else if (height) {
        pageTitleElement.style.height = height + 'px'
        return () => {
          pageTitleElement.style.height = 'auto'
        }
      }
    }
  }, [pageTitleElement, hide, height])

  if (!pageTitleElement) return null

  return ReactDOM.createPortal(children, pageTitleElement)
}

export const ContentTop = ({ children, hide = false }) => {
  const contentTopElement = document.getElementById('MainTemplate-contentTop')

  useEffect(() => {
    if (contentTopElement) {
      if (hide) {
        contentTopElement.style.display = 'none'
        return () => {
          contentTopElement.style.display = 'block'
        }
      }
    }
  }, [contentTopElement, hide])

  if (!contentTopElement) return null

  return ReactDOM.createPortal(children, contentTopElement)
}

export const Content = ({ children }) => {
  const contentElement = document.getElementById('main')

  if (!contentElement) return null

  return ReactDOM.createPortal(children, contentElement)
}

const TitleAnnouncer = (props) => {
  const [title, setTitle] = React.useState('')
  const titleRef = React.createRef()
  const { pathName } = props

  React.useEffect(() => {
    if (titleRef.current) titleRef.current.focus()
  }, [pathName])

  return (
    <>
      <p tabIndex={-1} ref={titleRef} className='sr-only'>
        {title}
      </p>
      <Helmet onChangeClientState={({ title }) => setTitle(title)} />
    </>
  )
}

const WrapperCSS = styled.div`
  background-color: transparent;
  &.coachBg {
    background-color: ${CSS_VARS.color_coach_primary};
  }
  &.flashBg {
    position: relative;
    z-index: 1;
    background: linear-gradient(180deg, ${CSS_VARS.color_brand_primary} 310px, transparent 310px);
  }
  @media only screen and (min-width: 600px) {
    background-color: transparent;
    &.coachBg {
      background-color: ${CSS_VARS.color_coach_primary};
    }
  }
  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    background-color: transparent;
    &.coachBg {
      background-color: ${CSS_VARS.color_coach_primary};
    }
  }
`

const MainCSS = styled.div`
  position: relative;
  z-index: 1;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  @media only screen and (min-width: 600px) {
    background: transparent;
  }
  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    min-height: calc(100vh - 310px); // to put footer at bottom of page even if less content
    background: transparent;
    padding-left: 15px;
    padding-right: 15px;
  }

  > .row {
    padding-left: 20px;
    padding-right: 20px;
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .first-row {
    min-height: 110px;
  }

  .main-row {
    min-height: 500px;
    @media only screen and (min-width: 600px) {
      min-height: 800px;
    }
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      min-height: 0;
      padding-bottom: 40px;
    }
  }

  .MainTemplate-pageTitle {
    min-height: 42px;
    padding: 20px 0;
    color: ${CSS_VARS.color_white};
    font-size: 22px;
    font-weight: ${CSS_VARS.weight_bold};
    text-align: center;

    .two-lines-title {
      display: block;
      margin: 0 auto;
    }

    @media (min-width: ${CSS_VARS.min_desktop_screen}) {
      padding: 0;
      font-size: 30px;

      .two-lines-title {
        display: block;
        max-width: 50%;
        margin: 0 auto;
      }
    }
  }
  .MainTemplate-contentTop {
    color: ${CSS_VARS.color_white};
    text-align: center;
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      min-height: 50px;
    }
    > div {
      width: 100%;
    }
  }
  .MainTemplate-content {
    padding: 0 0 30px;
    outline: none;
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      padding: 0;
    }
  }

  &.hideHeaderBackground {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 100vw;
      height: 100%;
      background-color: ${(props) =>
        props.isTanEnrolmentPage ? CSS_VARS.color_tan : 'transparent'};
      background-image: ${(props) =>
        props.isTanEnrolmentPage
          ? null
          : (props.loginBgImageURL.mobile,
            `linear-gradient(to right, ${CSS_VARS.color_product_primary}, ${CSS_VARS.color_product_primary_alt})`)};
      background-position: top 0 left 0;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media only screen and (min-width: 600px) {
      &:after {
        background-color: ${(props) =>
          props.isTanEnrolmentPage ? CSS_VARS.color_tan : 'transparent'};
        background-image: ${(props) =>
          props.isTanEnrolmentPage
            ? null
            : (props.loginBgImageURL.desktop,
              `linear-gradient(to right, ${CSS_VARS.color_product_primary}, ${CSS_VARS.color_product_primary_alt})`)};
        background-position: top 0 left 0;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      height: auto;
      padding-top: 0;
      // min-height: calc(100vh - 240px); // screen height - footer height
      min-height: calc(100vh - 46px); // screen height - footer height
    }

    .first-row {
      min-height: 0;
    }
    .main-row {
      padding-left: 15px;
      padding-right: 15px;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      padding-top: ${(props) => (props.isTanEnrolmentPage ? '0' : '70px')};

      .MainTemplate-content {
        flex: 1;
        padding: 0;
        display: flex;
        flex-direction: column;
        > div {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
      @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
        min-height: calc(100vh - 46px);
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        .SideNav {
          display: ${(props) =>
            props.isTanEnrolmentPage ? 'none !important' : 'block !important'};
        }
      }
    }
  }
`

const SideNavWrapper = React.memo(
  ({
    isCoachAvailableForUser,
    userSponsorFlag,
    userSponsorEvents,
    isOfferAvailable,
    isSponsorshipOfferAvailable,
    isSponsorshipEventsAvailable,
    hasMinorAccounts,
    hasProxyAccounts,
    isCatFlashAvailable,
    catFlashAuthorizedIds,
    isCatGreenAvailable,
    clientNumber,
    userTanStatus,
    BOSettings,
  }) => {
    const [showCoach, setShowCoach] = useState(isCoachAvailableForUser)
    useEffect(() => {
      setShowCoach(isCoachAvailableForUser)
    }, [isCoachAvailableForUser])
    return (
      <div className='col-md-3'>
        <SideNav
          hideCoach={!showCoach}
          userSponsorFlag={userSponsorFlag}
          userSponsorEvents={userSponsorEvents}
          isSponsorshipOfferAvailable={isSponsorshipOfferAvailable}
          isSponsorshipEventsAvailable={isSponsorshipEventsAvailable}
          isOfferAvailable={isOfferAvailable}
          hasMinorAccounts={hasMinorAccounts}
          hasProxyAccounts={hasProxyAccounts}
          isCatFlashAvailable={isCatFlashAvailable}
          catFlashAuthorizedIds={catFlashAuthorizedIds}
          isCatGreenAvailable={isCatGreenAvailable}
          clientNumber={clientNumber}
          userTanStatus={userTanStatus}
          BOSettings={BOSettings}
        />
      </div>
    )
  }
)

const MainTemplate = ({
  isAuthenticated,
  isCoachAvailableForUser,
  userSponsorFlag,
  userSponsorEvents,
  isOfferAvailable,
  isCatFlashAvailable,
  catFlashAuthorizedIds,
  isCatGreenAvailable,
  clientNumber,
  isSponsorshipOfferAvailable,
  isSponsorshipEventsAvailable,
  hasMinorAccounts,
  hasProxyAccounts,
  loginBgImageURL,
  userTanStatus,
  BOSettings,
}) => {
  const location = useLocation()
  const { pathname } = location
  const boRequester = useMemo(() => new BORequester(), [])

  useEffect(() => {
    const getFlashSettings = async () => {
      const CatFlashSettings = await boRequester.getCatFlashSettings()
      if (!CatFlashSettings.error) {
        updateCatFlashSettings(CatFlashSettings?.data)
      }
    }

    const getGreenSettings = async () => {
      const CatGreenSettings = await boRequester.getCatGreenSettings()
      if (!CatGreenSettings.error) {
        updateCatGreenSettings(CatGreenSettings?.data)
      }
    }

    getFlashSettings()
    getGreenSettings()
  }, [])

  const currentRoute = routes.filter((route) => route.path === pathname)
  const showHeaderBackground = currentRoute[0].showHeaderBackground
  const mobileUrl = loginBgImageURL?.mobile || ''
  const desktopUrl = loginBgImageURL?.desktop || ''

  const isLogin = pathname === '/login'

  const isCoachPage = pathname === '/menu_coach'
  const isFlashPage =
    pathname === '/openFlashTermAccount' || pathname === 'openFlashTermAccountCallback'
  const isTanEnrolmentPage = pathname === '/tanEnrol' || pathname === '/lostPassword'

  return (
    <WrapperCSS className={`${isCoachPage ? 'coachBg' : isFlashPage ? 'flashBg' : ''}`}>
      <TitleAnnouncer pathName={pathname} />
      <Header />
      <MainCSS
        className={`container ${
          showHeaderBackground ? 'showHeaderBackground' : 'hideHeaderBackground'
        }`}
        loginBgImageURL={{
          mobile: `url(${mobileUrl})`,
          desktop: `url(${desktopUrl})`,
        }}
        isTanEnrolmentPage={isTanEnrolmentPage}
        role='main'>
        <div className='row first-row'>
          <div className='col-sm-12'>
            <div
              id='MainTemplate-pageTitle'
              className={`MainTemplate-pageTitle ${
                isCoachPage ? 'coach-pageTitle' : isFlashPage ? 'flash-pageTitle' : ''
              }`}
            />
            <div
              id='MainTemplate-contentTop'
              className={`MainTemplate-contentTop ${
                isCoachPage ? 'coach-contentTop' : isFlashPage ? 'flash-pageTitle' : ''
              }`}
            />
          </div>
        </div>
        <div className='row main-row'>
          {isAuthenticated && (
            <SideNavWrapper
              isCoachAvailableForUser={isCoachAvailableForUser}
              userSponsorFlag={userSponsorFlag}
              userSponsorEvents={userSponsorEvents}
              isSponsorshipOfferAvailable={isSponsorshipOfferAvailable}
              isSponsorshipEventsAvailable={isSponsorshipEventsAvailable}
              isOfferAvailable={isOfferAvailable}
              hasMinorAccounts={hasMinorAccounts}
              hasProxyAccounts={hasProxyAccounts}
              isCatFlashAvailable={isCatFlashAvailable}
              catFlashAuthorizedIds={catFlashAuthorizedIds}
              isCatGreenAvailable={isCatGreenAvailable}
              clientNumber={clientNumber}
              userTanStatus={userTanStatus}
              BOSettings={BOSettings}
            />
          )}
          <main tabIndex={0} id='main' className='MainTemplate-content' />
        </div>
      </MainCSS>
      <Footer currentPage={isLogin ? 'login' : isTanEnrolmentPage ? 'tan' : 'current'} />
    </WrapperCSS>
  )
}

const mapStateToProps = ({
  cache: {
    person,
    isCoachAvailableForUser,
    sponsorEvents,
    hasMinorAccounts,
    hasProxyAccounts,
    isCatFlashAvailable,
    catFlashAuthorizedIds,
    isCatGreenAvailable,
    isOfferAvailable,
    isSponsorshipOfferAvailable,
    isSponsorshipEventsAvailable,
    loginBgImageURL,
    userTanStatus,
    BOSettings,
  },
  children,
}) => {
  const personExtId = (person || {}).personExtId || ''
  const clientNumber = getClientNumber(personExtId)

  let token = ''
  if (utils.app.get('session')) {
    const { idToken } = utils.app.get('session')
    token = idToken || ''
  }

  return {
    children,
    isAuthenticated: Boolean(token),
    isCoachAvailableForUser,
    userSponsorFlag: person?.sponsorFlag || false,
    userSponsorEvents: sponsorEvents || [],
    isSponsorshipOfferAvailable,
    isSponsorshipEventsAvailable,
    isOfferAvailable,
    isCatFlashAvailable: isCatFlashAvailable || false,
    catFlashAuthorizedIds,
    isCatGreenAvailable: isCatGreenAvailable || false,
    clientNumber,
    hasMinorAccounts,
    hasProxyAccounts,
    loginBgImageURL,
    userTanStatus,
    BOSettings,
  }
}

export default connect(mapStateToProps, true)(MainTemplate)
