import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import { clearViewState } from 'hocs'
import { setState } from 'services/store/actions'
import CopartisRequester from 'services/api/CopartisRequester'
import { _APP_PATHS } from 'services/constants'

import LostPasswordView from './LostPasswordView'

const LostPasswordContainer = ({ history }) => {
  const copartisRequester = useMemo(() => new CopartisRequester(), [])

  const [isPendingTempPassword, setIsPendingTempPassword] = useState(false)
  const [userId, setUserId] = useState('')
  const [currentStep, setCurrentStep] = useState(1)

  useEffect(() => {
    setState({
      goToStep,
      goToLoginScreen,
      submitTempPasswordForm,
      isPendingTempPassword,
    })
  }, [])

  const goToStep = (step) => {
    setCurrentStep(step)
  }

  const goToLoginScreen = () => {
    history.push(_APP_PATHS.login)
  }

  const submitTempPasswordForm = async ({ recaptcha, birthDate, userId, zipCode, iban }) => {
    setIsPendingTempPassword(true)
    const formatedIban = iban.replace(/\s+/g, '')
    const tempPassword = await copartisRequester.getTempPassword(recaptcha, userId, {
      birthDate,
      iban: formatedIban,
      zipCode,
    })

    if (tempPassword && !tempPassword.error) {
      setIsPendingTempPassword(false)
      setUserId(userId)
      goToStep(3)
    } else {
      setIsPendingTempPassword(false)
    }
  }

  return <LostPasswordView currentStep={currentStep} />
}

export default clearViewState(withRouter(LostPasswordContainer))
