import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormSupprCoTit = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>
            Demande de transformation d'un compte joint en compte individuel
          </h2>
          <div className='pdf-section-content'>
            <span className='pdf-highlighted'>Nous soussignés,</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${birthName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span>{birthName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
          </div>
          <div className='pdf-section-content'>
            <br />
            <span className='pdf-highlighted'>Et</span>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Prénom(s)&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Référence&nbsp;client&nbsp;: <span></span>
            </div>

            <p className='pdf-text'>
              <br />
              <br />
              Demandons, en tant que co-titulaires du compte joint référencé ci-dessus ouvert dans
              vos livres à nos noms, la transformation dudit compte en compte individuel au seul nom
              de&nbsp;: ...............................................
              <br />
              De ce fait, le compte fonctionnera, dès traitement de la présente demande par vos
              services, uniquement comme un compte individuel ouvert au nom du titulaire ci-dessus
              mentionné. Ceci étant, il est entendu que nous demeurons solidairement responsables
              des opérations engagées sur le compte antérieurement à la date de sa transformation.
            </p>
            <p className='pdf-text'>
              <br />
              En outre, nous vous informons (cochez la case ci-dessous si besoin)&nbsp;:
            </p>

            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' />
                <span className='case' />
                <label>
                  que nous révoquons la (les) procuration(s) délivrée(s) sur le compte à la (aux)
                  personne(s) ci-après désignée(s). Il est entendu que nous demeurons responsables
                  des opérations engagées sur le compte par le(s) mandataire(s) antérieurement à la
                  date de réception de la présente par DISTINGO Bank et que nous informerons cette
                  (ces) personne(s) de notre décision et ses conséquences.
                  <br />
                  Nous vous remercions par conséquent de procéder dès réception de la présente aux
                  modifications nécessaires dans vos livres.
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className='pdf-section'>
          <div className='pdf-section-content'>
            <p className='pdf-highlighted'>
              Chaque signataire reconnaît et accepte expressément&nbsp;:
            </p>
            <ul className='pdf-list'>
              <li>
                Que la présente demande, une fois acceptée par DISTINGO Bank, a valeur d'avenant à
                la convention de compte sur Livret dont les références sont précisées ci-dessus mais
                ne remet pas en cause les autres conditions particulières existantes, non contraires
                et non modifiées, applicables à ladite convention et qui subsistent donc&nbsp;;
              </li>
              <li>
                Qu'une demande de confirmation écrite de son accord leur sera adressée par DISTINGO
                Bank&nbsp;;
              </li>
              <li>
                Avoir pris connaissance et accepter sans réserve les Conditions Générales
                applicables au Livret et les Conditions Tarifaires DISTINGO Bank en vigueur&nbsp;;
              </li>
              <li>Disposer d'un exemplaire de chacun des documents et conditions susvisés.</li>
            </ul>
          </div>
        </section>

        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
            <br />
            <br />
          </div>

          <table className='pdf-sign-table smallest double'>
            <tbody>
              <tr>
                <td>Signature du titulaire du compte</td>
                <td>Signature du co-titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title="suppression d'un co-titulaire"
        isFirstPage
        hasPagination
        totalPages={2}
        currentPage={1}
        formRef='SUCO042023'>
        {renderPage1Content()}
      </PdfFormPageTemplate>

      {/* page 2 */}
      <PdfFormPageTemplate
        isCopy
        title="suppression d'un co-titulaire"
        isFirstPage
        hasPagination
        totalPages={2}
        currentPage={2}
        formRef='SUCO042023'>
        {renderPage1Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormSupprCoTit
