import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/remote-config'

import utils from 'services/utils'
import {
  setState,
  showNotification,
  updateOfferAvailability,
  updateSponsorshipOfferAvailability,
  updateSponsorshipEventsAvailability,
  updateLoginBgImageURL,
  updateCatFlashSettings,
  updateCatGreenSettings,
  updateUserTanStatus,
  updateTanEnrolmentStep,
} from 'services/store/actions'
import BORequester from 'services/api/BORequester'
import CopartisRequester from 'services/api/CopartisRequester'
import intl from 'services/intl'
import { connect } from 'services/store'
import { clearViewState } from 'hocs'
import { _APP_PATHS } from 'services/constants'

import LoginView from './LoginView'

class LoginContainer extends Component {
  constructor(props) {
    super(props)

    this.copartisRequester = new CopartisRequester()
    this.BORequester = new BORequester()

    setState({
      isPending: false,
      login: this.login,
      recoveryPassword: this.recoveryPassword,
      message: null,
      isLoginEnabled: true, // default is no value from BO
      chooseMockProfile: this.chooseMockProfile,
      selectedMock: utils.app.get('mockOption', false) || 'none',
      goToLostPassword: this.goToLostPassword,
    })

    firebase.analytics().logEvent('screen_view', { page_location: window.location.href })
  }

  async componentDidMount() {
    if (utils.app.get('sessionExpired')) {
      showNotification({
        type: 'error',
        title: intl`error_session_expired`,
      })

      utils.app.remove('sessionExpired')
    }
    utils.app.remove('session')

    if (utils.app.get('displayResetMessageSuccess')) {
      showNotification({
        type: 'success',
        title: intl`modify_password_success_title`,
        text: intl`modify_password_success_text`,
      })
      utils.app.remove('displayResetMessageSuccess')
    }

    const BOSettings = await this.BORequester.getSettings()
    if (!BOSettings.error) {
      const loginSetting = BOSettings?.data?.login_setting
      if (loginSetting) {
        setState({ isLoginEnabled: loginSetting.enabled })
        updateLoginBgImageURL({
          mobile: loginSetting.mobile_website_image_url,
          desktop: loginSetting.website_image_url,
        })
      }
    }

    const message = await this.BORequester.getMessage('login')

    // Treats message data
    if (message.error) {
    } else {
      setState({ message: message.data })
    }
  }

  render() {
    return <LoginView />
  }

  login = async (username, password, hasIdentifierMemoized, simulateKyc) => {
    setState({ isPending: true })

    const { data, error } = await this.copartisRequester.login({
      username: username,
      password: password,
    })

    if (error) {
      setState({ isPending: false })
      if (data && data.code === '124') {
        showNotification({
          type: 'error',
          title: intl`login_blocked_title`,
          text: `${intl('login_blocked_3')}<br><br>${intl('login_blocked_2')}`,
          html: true,
        })
      } else {
        showNotification({
          type: 'error',
          title: intl`login_error_title`,
          text: `${intl('login_error')}<br><br>${intl('login_blocked_1')}<br><br>${intl(
            'login_blocked_2'
          )}`,
          html: true,
        })
      }
    } else {
      // fetch remoteConfig values
      firebase
        .remoteConfig()
        .fetchAndActivate()
        .then(() => {
          const isNewCatEnabled = (firebase.remoteConfig().getValue('is_new_cat_enabled') || {})
            ._value
          if (typeof isNewCatEnabled !== 'undefined' && isNewCatEnabled !== '') {
            utils.app.set('isNewCatEnabled', isNewCatEnabled)
          } else {
            utils.app.set('isNewCatEnabled', false)
          }
        })
        .catch((err) => {
          // console.log('activated error')
          // console.log(err)
        })

      const { id_token, firstAccess, kycAccess, lastLoginDate } = data || {}

      firebase.analytics().logEvent('login')

      window.dataLayer.push({
        event: 'psa_identification',
        psa_memorisationidentifiant: hasIdentifierMemoized,
        psa_userID: username,
      })

      const BOSettings = await this.BORequester.getSettings()
      if (!BOSettings.error) {
        const pagesSetting = BOSettings?.data?.pages_setting || []
        const fidelityPage = pagesSetting.filter((page) => page.slug === 'offer')?.[0]
        const sponsorshipOfferPage = pagesSetting.filter(
          (page) => page.slug === 'sponsorship_offer'
        )?.[0]
        const sponsorshipEventsPage = pagesSetting.filter(
          (page) => page.slug === 'sponsorship_tracking'
        )?.[0]

        updateOfferAvailability(fidelityPage ? fidelityPage.enabled : true)
        updateSponsorshipOfferAvailability(
          sponsorshipOfferPage ? sponsorshipOfferPage.enabled : true
        )
        updateSponsorshipEventsAvailability(
          sponsorshipEventsPage ? sponsorshipEventsPage.enabled : true
        )
      }

      // get CAT flash settings
      const CatFlashSettings = await this.BORequester.getCatFlashSettings()
      if (!CatFlashSettings.error) {
        updateCatFlashSettings(CatFlashSettings?.data)
      }

      // get CAT Green settings
      const CatGreenSettings = await this.BORequester.getCatGreenSettings()
      if (!CatGreenSettings.error) {
        updateCatGreenSettings(CatGreenSettings?.data)
      }

      setState({ isPending: false })

      if (firstAccess) {
        // save temp token in sessionStorage to redirect to home page after form is complete
        utils.app.set('session', { tempToken: id_token, cId: username, lastLoginDate })
        this.props.history.push(_APP_PATHS.resetPassword)
      } else {
        if (kycAccess || simulateKyc) {
          // save temp token in sessionStorage to redirect to home page after Kyc is complete
          utils.app.set('session', { tempToken: id_token, cId: username, lastLoginDate })
          this.props.history.push(_APP_PATHS.kyc)
        } else {
          const tanStatus = await this.copartisRequester.getTanStatus(id_token)
          let userTanStatus = ''
          if (!tanStatus.error) {
            const userTanData = tanStatus?.data || {}
            updateUserTanStatus(userTanData)
            userTanStatus = userTanData?.status || ''
            updateTanEnrolmentStep(userTanStatus === 'W' || userTanStatus === 'Y' ? 5 : 1)
          }
          if (userTanStatus !== 'A') {
            // show enrolment screen 1 / 3 connexions
            const localUsersSettings = utils.app.getLocal('localUsersSettings')
            const nbConnexions =
              localUsersSettings && localUsersSettings[username]
                ? parseInt(localUsersSettings[username]?.nbConnexions ?? 1)
                : 1
            // update nbConnexions
            const updatedNmConnexions = nbConnexions === 3 ? 1 : nbConnexions + 1
            const userSettings =
              localUsersSettings && localUsersSettings[username] ? localUsersSettings[username] : {}
            const newUsersSettings = {
              ...localUsersSettings,
              [username]: { ...userSettings, nbConnexions: updatedNmConnexions },
            }
            utils.app.setLocal('localUsersSettings', newUsersSettings)
            if (nbConnexions === 1) {
              // go to enrolment
              // save temp token in sessionStorage to redirect to home page after form is complete
              utils.app.set('session', { tempToken: id_token, cId: username, lastLoginDate })
              this.props.history.push(_APP_PATHS.tanEnrol)
            } else {
              // save token in sessionStorage to redirect to home page (via routePermissionsHoc)
              // look at query to redirect or not
              const path = utils.getUrlParts(window.location.href)?.path || ''
              const redirection = utils.getUrlQueryparam(path, 'goto')
              if (redirection && _APP_PATHS[redirection]) {
                utils.app.set('session', { idToken: id_token, cId: username, lastLoginDate })
                this.props.history.push(_APP_PATHS[redirection])
              } else {
                utils.app.set('session', { idToken: id_token, cId: username, lastLoginDate })
              }
            }
          } else {
            // save token in sessionStorage to redirect to home page (via routePermissionsHoc)
            // look at query to redirect or not
            const path = utils.getUrlParts(window.location.href)?.path || ''
            const redirection = utils.getUrlQueryparam(path, 'goto')
            /* console.log('path : ', path)
            console.log('redirection : ', redirection)
            console.log('_APP_PATHS[redirection] : ', _APP_PATHS[redirection]) */
            if (redirection && _APP_PATHS[redirection]) {
              utils.app.set('session', { idToken: id_token, cId: username, lastLoginDate })
              this.props.history.push(_APP_PATHS[redirection])
            } else {
              utils.app.set('session', { idToken: id_token, cId: username, lastLoginDate })
            }
          }
        }
      }
    }

    setState({ isPending: false })

    return window.Promise.resolve()
  }

  recoveryPassword = async (identifer, bithdate, channel) => {
    setState({ isPending: true })

    const result = await this.copartisRequester.recoveryPassword(identifer, bithdate, channel)

    setState({ isPending: false })

    if (!result.error) {
      showNotification({
        type: 'success',
        title:
          intl`forgotten_password_success_text_1` +
          ` ` +
          (channel === 'SMS'
            ? intl`forgotten_password_option_1`
            : intl`forgotten_password_option_2`),
      })

      return window.Promise.resolve()
    }

    return window.Promise.reject()
  }

  chooseMockProfile = (e) => {
    const selectedOption = e.target.value
    utils.app.set('mockOption', selectedOption)
    setState({
      selectedMock: selectedOption,
    })
  }

  goToLostPassword = () => {
    this.props.history.push(_APP_PATHS.lostPassword)
  }
}

const mapStateToProps = (props) => {
  return {
    history: props.history,
  }
}

export default clearViewState(withRouter(connect(mapStateToProps)(LoginContainer)))
