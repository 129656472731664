import React, { useState, useEffect } from 'react'

import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'
import { connect } from 'services/store'
import { hideOtpInput } from 'services/store/actions'
import { _API_URLS } from 'services/constants'
import { Modal, Field } from 'uic'

const ModalContent = styled.div`
  padding: 30px;
  text-align: center;

  h2 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: ${CSS_VARS.weight_bold};
    line-height: 26px;
    color: ${CSS_VARS.color_brand_secondary};
  }
  .psa-inputText {
    font-size: 16px !important;
    ::-webkit-input-placeholder {
      color: #bbb;
    }
    ::-moz-placeholder {
      color: #bbb;
    }
    :-ms-input-placeholder {
      color: #bbb;
    }
    :-moz-placeholder {
      color: #bbb;
    }
  }
  .psa-button {
    display: block;
    margin: 10px auto 0;
  }
  .messageInfo {
    margin: 0 0 30px;
    font-style: italic;
    font-size: ${CSS_VARS.size_xs};
  }
  .messageSuccess {
    margin: 0 0 30px;
  }
`

const OTPModal = ({
  sentCodeText,
  resentCodeText,
  otpOperation,
  otpRoute,
  otpRequestBody,
  otpCopartisRequester,
  otpOnError,
  otpOnSuccess,
  otpCloseCallback,
}) => {
  const [otp, setOtp] = useState('')
  const [canResendCode, setCanResendCode] = useState(false)
  const [isCodeResent, setIsCodeResent] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    let ignore = false
    if (!ignore && otpRoute) {
      window.setTimeout(() => {
        setCanResendCode(true)
      }, 60000)
    }
    return () => {
      ignore = true
    }
  }, [otpRoute])

  const onClose = () => {
    setOtp('')
    otpCloseCallback()
    hideOtpInput()
    setCanResendCode(false)
  }

  const resendCode = async () => {
    const response = await otpCopartisRequester.secureOperation(
      otpOperation,
      otpRoute,
      otpRequestBody,
      otpOnError,
      otpOnSuccess,
      otpCloseCallback
    )

    if (!response || response.error) {
      otpOnError(otpRoute, otpRequestBody, response?.data)
    } else {
      setIsCodeResent(true)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isSubmitting) {
      return
    }
    setIsSubmitting(true)
    const validation = await otpCopartisRequester.validateOperation(
      otpOperation,
      otpRoute,
      otpRequestBody,
      otp
    )
    if (validation.error) {
      otpOnError(otpRoute, otpRequestBody, validation?.data)
      setIsSubmitting(false)
    } else {
      otpOnSuccess(validation?.data)
      setOtp('')
      setIsSubmitting(false)
      setCanResendCode(false)
      hideOtpInput()
    }
  }

  if (!otpRoute) return null

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <h2>{intl`tan_popup_title`}</h2>
        <div>
          <p>{sentCodeText}</p>
          <Field fieldId='codeOTP' className='field floatLabelField'>
            <input
              className='psa-inputText'
              id='codeOTP'
              name='codeOTP'
              type='text'
              data-required
              aria-required='true'
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder={intl`tan_popup_placeholder`}
            />
            <label className='sr-only' htmlFor='codeOTP'>
              {intl`tan_popup_placeholder`}
            </label>
          </Field>
          <button
            type='button'
            className='psa-button isLink stepComponent3-link'
            onClick={resendCode}
            disabled={!canResendCode}>
            {intl`tan_popup_cta_1`}
          </button>
          {!canResendCode && <p className='messageInfo'>{intl`tan_popup_cta_info`}</p>}
          {isCodeResent && <p className='messageSuccess'>{resentCodeText}</p>}
          <button
            type='submit'
            className='psa-button isPrimary'
            onClick={handleSubmit}
            disabled={!otp}>{intl`tan_popup_cta_2`}</button>
        </div>
      </ModalContent>
    </Modal>
  )
}

const mapSateToProps = ({
  cache: { person, userTanStatus },
  view: {
    otpCodeType,
    otpRoute,
    otpOperation,
    otpRequestBody,
    otpCopartisRequester,
    otpOnError,
    otpOnSuccess,
    otpCloseCallback,
  },
}) => {
  const { contact } = person || {}
  const mobilePhones = (contact?.phones || []).filter((p) => p.type === 'MOBILE')
  const mobile = mobilePhones.length ? mobilePhones[0] : ''
  const email = (contact?.emails?.[0] || {}).address

  const phoneNumber =
    otpCodeType === 'TAN_REQUIRED' ? userTanStatus?.phoneNumber ?? '' : mobile?.number ?? ''

  let sentCodeText = `${intl('tan_popup_text_1')} ${intl('tan_popup_text_phone')} ${phoneNumber}`
  let resentCodeText = `${intl('tan_popup_resend_success')} ${intl(
    'tan_popup_text_phone'
  )} ${phoneNumber}`
  if (
    (otpCodeType === 'OTP_REQUIRED' || otpCodeType === 'TAN_REQUIRED') &&
    otpRoute.indexOf(_API_URLS.postPhones) >= 0
  ) {
    // code sent on email address
    sentCodeText = `${intl('tan_popup_text_1')} ${intl('tan_popup_text_email')} ${email}`
    resentCodeText = `${intl('tan_popup_resend_success')} ${intl('tan_popup_text_email')} ${email}`
  }

  return {
    sentCodeText,
    resentCodeText,
    otpRoute,
    otpOperation,
    otpRequestBody,
    otpCopartisRequester,
    otpOnError,
    otpOnSuccess,
    otpCloseCallback,
  }
}

export default connect(mapSateToProps)(OTPModal)
