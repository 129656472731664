import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import intl from 'services/intl'
import styled, { CSS_VARS } from 'services/styled'
import { connect } from 'services/store'
import { showNotification } from 'services/store/actions'
import utils from 'services/utils'
import { CustomCheckbox, Form, Field, MessageBox } from 'uic'
import { _CONF } from 'services/constants'
import { ReactComponent as PasswordHideIcon } from 'assets/svg/passwordHide.svg'
import { ReactComponent as PasswordShowIcon } from 'assets/svg/passwordShow.svg'

const MainCSS = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 70px 0 20px;
  background: ${CSS_VARS.color_white};
  text-align: center;

  > form {
    width: 100%;

    fieldset {
      max-width: 270px;
      margin: 0 auto;
      border: none;

      > .field {
        margin-bottom: 10px;
      }
      > .psa-button.isLink {
        margin: 10px 0;
        text-transform: none;
        font-weight: ${CSS_VARS.weight_medium};
        &:after {
          width: 10px;
          height: 10px;
        }
      }
      > .memoizeIdentiferField {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        font-size: ${CSS_VARS.size_xs};
        > span {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
      .psa-showPassword {
        position: absolute;
        right: 8px;
        top: 10px;
        > svg {
          width: 20px;
          fill: ${CSS_VARS.color_grey};
        }
        > span {
          font-size: 10px;
        }
      }
      .psa-tooltip-trigger {
        position: absolute;
        right: 8px;
        top: 15px;
      }
    }
    .mentions {
      display: block;
      margin: 0 auto 10px;
      max-width: 270px;
      font-style: italic;
      font-size: ${CSS_VARS.size_xxs};
      text-align: left;
      &.sr-only {
        margin: 0 auto;
      }
    }
  }

  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    padding: 50px 0 0;

    > form {
      .psa-blockLegend {
        left: -16px;
        top: -16px;
        font-size: ${CSS_VARS.size_m};
        padding: 12px;
      }

      fieldset {
        max-width: 320px;
        > .field {
          margin-bottom: 15px;
        }
        > .link {
          margin: 20px 0;
        }

        > .psa-button.isLink {
          margin: 20px 0;
          &:after {
            width: 14px;
            height: 14px;
          }
        }

        .psa-showPassword {
          right: 12px;
        }

        .psa-tooltip-trigger {
          right: 12px;
        }
      }
      .mentions {
        max-width: 320px;
        &.sr-only {
          margin: 0 auto;
        }
      }
    }
  }

  .lds-dual-ring {
    display: inline-block;
    width: 34px;
    height: 34px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 26px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${CSS_VARS.color_brand_primary};
    border-color: ${CSS_VARS.color_brand_primary} transparent ${CSS_VARS.color_brand_primary}
      transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const MessageWrapperCSS = styled.div`
  position: relative;
`

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isPasswordShown: false,
      hasIdentifierMemoized: Boolean(utils.app.getLocal('userIdMemoized', false)),
      memoizedIdentifier: Boolean(utils.app.getLocal('userIdMemoized', false))
        ? utils.app.getLocal('userIdMemoized', false)
        : null,
    }
    this.identifier = React.createRef()
    this.simulateKyc = false
  }

  render() {
    const { isPending, message, isLoginEnabled } = this.props
    const { isPasswordShown, hasIdentifierMemoized, memoizedIdentifier } = this.state
    return (
      <>
        <ReactTooltip effect='solid' className='psa-tooltip' globalEventOff='click' />
        {message && (
          <MessageWrapperCSS>
            <MessageBox htmlContent={message} noHeaderPage />
          </MessageWrapperCSS>
        )}
        <MainCSS>
          <Form onSubmit={this.handleSubmit}>
            <legend className='psa-blockLegend'>{intl`login_text_2`}</legend>
            <fieldset>
              <Field className='field floatLabelField' fieldId='username'>
                <input
                  className={`psa-inputText filled filled-freezed`}
                  ref={this.identifier}
                  type='text'
                  id='username'
                  name='username'
                  autoComplete='username'
                  data-required
                  aria-required='true'
                  defaultValue={memoizedIdentifier}
                  title={intl`login_placeholder_1`}
                  maxLength={11}
                  aria-describedby='login_info_identifier'
                />
                <label htmlFor='username'>{intl`login_placeholder_1`}</label>
                <button
                  type='button'
                  className='psa-tooltip-trigger psa-tooltip-trigger-light'
                  tabIndex={0}
                  data-tip={intl`login_info_identifier`}
                />
              </Field>
              <Field className='field floatLabelField' fieldId='password'>
                <input
                  className={`psa-inputText filled filled-freezed`}
                  type={isPasswordShown ? 'text' : 'password'}
                  id='password'
                  name='password'
                  autoComplete='current-password'
                  data-required
                  aria-required='true'
                  title={intl`login_placeholder_2`}
                />
                <label htmlFor='password'>{intl`login_placeholder_2`}</label>
                {isPasswordShown ? (
                  <button
                    className='psa-showPassword'
                    type='button'
                    onClick={() => this.setState({ isPasswordShown: false })}>
                    <PasswordHideIcon />
                    <span className='sr-only'>{intl`login_toggle_2`}</span>
                  </button>
                ) : (
                  <button
                    className='psa-showPassword'
                    type='button'
                    onClick={() => this.setState({ isPasswordShown: true })}>
                    <PasswordShowIcon />
                    <span className='sr-only'>{intl`login_toggle_1`}</span>
                  </button>
                )}
              </Field>
              <div className='memoizeIdentiferField'>
                <CustomCheckbox
                  id='memoizeIdentifier'
                  name='memoizeIdentifier'
                  onChange={this.memoizeIdentifier}
                  defaultChecked={hasIdentifierMemoized}
                />
                <label htmlFor='memoizeIdentifier'>{intl`login_placeholder_3`}</label>
              </div>

              {isPending ? (
                <div>
                  <div className='lds-dual-ring' />
                  <div>Veuillez patienter</div>
                </div>
              ) : (
                <button
                  className='psa-button isPrimary'
                  type='submit'
                  disabled={!isLoginEnabled}>{intl`login_cta_2`}</button>
              )}
              <button
                type='button'
                className='psa-button isLink'
                onClick={this.onForgotPasswordClick}>
                {intl`login_cta_1`} ?
              </button>
            </fieldset>
          </Form>
        </MainCSS>
      </>
    )
  }

  handleSubmit = ([username, password]) => {
    const { hasIdentifierMemoized } = this.state
    return this.props
      .login(username.value, password.value, hasIdentifierMemoized, this.simulateKyc)
      .then(() => {
        if (hasIdentifierMemoized) {
          utils.app.setLocal('userIdMemoized', username.value)
        } else if (!hasIdentifierMemoized && utils.app.getLocal('userIdMemoized', false)) {
          utils.app.removeLocal('userIdMemoized')
        }

        return window.Promise.resolve()
      })
  }

  memoizeIdentifier = (event) => {
    this.setState({
      hasIdentifierMemoized: !!event.target.checked,
    })
  }

  handleKycSimulation = (event) => {
    this.simulateKyc = !!event.target.checked
  }

  onForgotPasswordClick = () => {
    window.dataLayer.push({
      event: 'psa_mdpoublie',
    })
    if (_CONF._LOST_PASSWORD_FORM) {
      this.props.goToLostPassword()
    } else {
      showNotification({
        type: 'warning',
        html: true,
        title: intl`modal_lost_password_1`,
        text: `<p>${intl('modal_lost_password_2')}<br>
        ${intl('modal_lost_password_3')}<br>
        ${intl('modal_lost_password_4')}</p>`,
      })
    }
  }
}

const mapStateToProps = ({
  view: { isPending, login, message, isLoginEnabled, goToLostPassword },
}) => {
  return {
    isPending,
    login,
    message,
    isLoginEnabled,
    goToLostPassword,
  }
}

export default connect(mapStateToProps)(LoginForm)
