import React, { useState } from 'react'

import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'
import { Field } from 'uic'

const Decimal = require('decimal.js-light')

const MainCSS = styled.section`
  position: relative;
  text-align: center;
  margin-bottom: 50px;

  .psa-blockContent {
    height: 100%;
  }
  .psa-blockLegend {
    max-width: 400px;
    left: -10px;
    background-color: #285971;
    text-align: left;
  }

  .field.floatLabelField {
    max-width: 320px;
    margin: 0 auto;

    label {
      left: 5px;
      padding: 0;
      font-size: 13px;
    }

    .psa-inputText {
      height: 40px;
      padding: 15px 40px 0 5px;
      background-color: transparent;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: ${CSS_VARS.color_brand_primary};
    }

    .symbol {
      position: absolute;
      right: 5px;
      top: 18px;
      font-size: 13px;
    }
  }
  .results {
    .label {
      margin-top: 30px;
      font-size: 17px;
      font-weight: 700;
    }
    .notice {
      font-size: 13px;
    }
    .number {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      font-weight: 700;
      > span {
        font-size: 32px;
      }
    }
    .duration button {
      margin: 10px;
      opacity: 0.4;
      font-weight: 700;
      text-transform: uppercase;
      &.active {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
  .validateCTA {
    margin: 30px 0 0;
  }

  @media only screen and (min-width: ${CSS_VARS.min_tablet_screen}) {
    flex: 0 0 45%;
    margin-bottom: 0;

    .psa-blockLegend {
      width: 90%;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
    }
    .psa-blockContent {
      margin-top: 10px;
      padding: 40px 20px 0;
    }
  }
`

const SimulatorCATFlash = ({ catFlashSettings }) => {
  const [interests, setInterests] = useState(0)

  const getInterests = (capital) => {
    const { rate } = catFlashSettings
    if (!capital || !rate) {
      return
    }
    const nCapital = new Decimal(capital)
    const nRate = new Decimal(rate).dividedBy(100)
    const multiplier = nRate.plus(1)

    const firstYearTotal = nCapital.times(multiplier) // 100000 * 1.035 = 103500
    const firstYearInterests = firstYearTotal.minus(nCapital) // 103500 - 100000 = 3500
    const threeMonthsInterests = new Decimal(firstYearTotal).times(nRate).dividedBy(4)
    setInterests(firstYearInterests.plus(threeMonthsInterests).toDecimalPlaces(2, 6).toNumber())
  }

  return (
    <MainCSS>
      <div className='psa-blockContent'>
        <div className='psa-blockLegend'>{intl`simulator_cat_flash_title`}</div>
        <Field fieldId='label' className='field floatLabelField'>
          <input
            className='psa-inputText'
            name='capital'
            id='capital'
            type='text'
            maxLength='32'
            title={intl`Votre capital`}
            data-required
            aria-required='true'
            onChange={(event) => {
              event.target.value = event.target.value.trim().replace(/[^0-9]/g, '')
              if (event.target.value) {
                getInterests(event.target.value)
              } else {
                setInterests(null)
              }
            }}
          />
          <label htmlFor='capital'>{intl`Votre capital`}*</label>
          <div className='symbol'>€</div>
        </Field>
        {!!interests && (
          <div className='results'>
            <div className='label'>{intl`simulator_result_4`}</div>
            <div className='notice'>{intl`simulator_result_5`}</div>
            <div className='number'>
              {interests}
              <span>€</span>
            </div>
            {/*<div className="duration">
                <span>sur {catFlashSettings?.duration || ''} mois</span>
              </div>*/}
          </div>
        )}
      </div>
    </MainCSS>
  )
}

export default SimulatorCATFlash
