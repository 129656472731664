import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './registerServiceWorker'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/remote-config'

import './assets/styles/bootstrap.css'

import App from './App'

const init = async () => {
  var firebaseConfig =
    process.env.REACT_APP_FIREBASE_PROJECT === 'preprod'
      ? {
          apiKey: 'AIzaSyDMFE_fnogYrJEErwVZeRtL0DR--ACTlb8',
          authDomain: 'psa-banque-preprod.firebaseapp.com',
          databaseURL: 'https://psa-banque-preprod.firebaseio.com',
          projectId: 'psa-banque-preprod',
          storageBucket: 'psa-banque-preprod.appspot.com',
          messagingSenderId: '1095199156371',
          appId: '1:1095199156371:web:4bc31bb75a1a014c83c566',
          measurementId: 'G-N0FXFCJH6X',
        }
      : {
          apiKey: 'AIzaSyC61rQHVnLFh3ib9wRxlYjkuMYeii_KvZs',
          authDomain: 'psa-banque-prod.firebaseapp.com',
          databaseURL: 'https://psa-banque-prod.firebaseio.com',
          projectId: 'psa-banque-prod',
          storageBucket: 'psa-banque-prod.appspot.com',
          messagingSenderId: '731364406136',
          appId: '1:731364406136:web:7e3ccf07e4101fa4290e8d',
          measurementId: 'G-6FME5LSWT1',
        }
  // Initialize Firebase
  // firebase.initializeApp(firebaseConfig)
  const app = firebase.initializeApp(firebaseConfig)

  firebase.analytics()

  const remoteConfig = firebase.remoteConfig(app)
  remoteConfig.settings.minimumFetchIntervalMillis = 60
  firebase
    .remoteConfig()
    .fetchAndActivate(remoteConfig)
    .then(() => {})
    .catch(() => {})

  unregister()

  ReactDOM.render(<App />, document.getElementById('root'))
}

init()
