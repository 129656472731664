import React, { Component } from 'react'
import styled, { CSS_VARS } from 'services/styled'
import uploadImg from 'assets/images/icones/upload.png'
import { showNotification } from '../services/store/actions'
import intl from '../services/intl'

const Container = styled.div`
  min-height: 50px;
  line-height: 50px;
  text-align: left;

  .psa-fieldError {
    line-height: 1;
    font-size: 12px;
  }
  .inputFileContainer {
    position: relative;
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    text-align: left;
    padding-left: 15px;
    background: ${CSS_VARS.color_background} url(${uploadImg}) right 20px center no-repeat;

    label:first-of-type {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      cursor: pointer;
      padding: 0;
      margin: 0;

      input {
        position: relative;
        width: 100%;
        height: 50px;
        cursor: pointer;
        padding: 0;
        margin: 0;
        z-index: -1;
      }
    }

    label:last-of-type {
      line-height: 50px;
      cursor: pointer;
    }
  }

  .mention {
    font-style: italic;
    font-size: 12px;
    line-height: 1.5;
  }

  .hidden {
    display: none;
    visibility: hidden;
  }

  .fileDescription {
    padding-left: 15px;

    > .fileInfos {
      float: left;
      line-height: 1.3;
      > span {
        display: block;
        font-size: 11px;
      }
    }

    .deleteFile {
      float: right;
      font-size: 22px;

      :hover,
      :focus {
        color: ${CSS_VARS.color_brand_primary};
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
`

export default class FileField extends Component {
  state = {
    file: null,
    id: this.props.name || performance.now(),
  }

  constructor(props) {
    super(props)
    this.file = React.createRef()
  }

  render() {
    const { style, name, label, isFloatLabel, mention } = this.props
    const { file } = this.state
    return (
      <Container className='inputFileWrapper' style={style}>
        <div className={file ? 'hidden' : 'inputFileContainer'}>
          <label>
            <input
              ref={this.file}
              type='file'
              name={name}
              id={this.state.id}
              onChange={this.uploadFile}
              className='fileUpload'
            />
          </label>
          {label && <label htmlFor={name}>{label}</label>}
        </div>

        <div className={file ? 'hidden' : 'psa-fieldError'} />

        {mention && <p className={file ? 'hidden' : 'mention'}>{mention}</p>}

        {file && (
          <div className='fileDescription'>
            {label && isFloatLabel && <label>{label}</label>}
            <span className='fileInfos'>
              {file.name}
              <span>{`(${Math.round(file.size / 10) / 100} Kb)`}</span>
            </span>
            <button type='button' className='deleteFile' onClick={this.deleteFile}>
              &times;
            </button>
          </div>
        )}
      </Container>
    )
  }

  uploadFile = (event) => {
    const maxSizeMo = this.props.maxSizeMo || 1000000
    const maxSizeText = this.props.maxSizeText || '1 Mo'
    try {
      Object.keys(event.target.files).forEach((key) => {
        let fileToLoad = event.target.files[key]

        if (fileToLoad.size > maxSizeMo) {
          showNotification({
            type: 'warning',
            text: intl`contact_file_limit` + ' ' + maxSizeText,
          })
          this.deleteFile()

          return
        }

        if (this.props.checkExtension && !fileToLoad.type) {
          showNotification({
            type: 'warning',
            text: intl`contact_email_form_client_add_photo_error_1_text_bis`,
          })
          this.deleteFile()

          return
        }

        if (this.props.authorisedTypes && this.props.authorisedTypes.length > 0) {
          if (fileToLoad.type && this.props.authorisedTypes.indexOf(fileToLoad.type) < 0) {
            showNotification({
              type: 'warning',
              text: intl`contact_email_form_client_add_photo_error_1_text_bis`,
            })
            this.deleteFile()

            return
          }
        }

        this.setState({
          file: fileToLoad,
        })

        if (this.props.onChange) {
          this.props.onChange({ name: this.props.name, file: fileToLoad })
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  deleteFile = () => {
    this.file.current.value = ''
    this.setState({
      file: null,
    })

    if (this.props.onChange) {
      this.props.onChange({ name: this.props.name, file: null })
    }
  }
}
