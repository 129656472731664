import React from 'react'
import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'

const MainCSS = styled.div`
  position: ${props => props.position === '' ? 'fixed' : props.position};
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: ${props => props.isInline ? 'flex-start' : 'center'};
  flex-direction: ${props => props.isInline ? 'row' : 'column'};
  width: 100%;
  height: 100%;
  font-size: ${CSS_VARS.size_xl};
  color: ${props => props.color === 'blue' ? CSS_VARS.color_brand_primary : '#ffffff'};
  .lds-dual-ring {
    display: inline-block;
    width: ${props => props.size === 'small' ? '26px' : '64px'};
    height: ${props => props.size === 'small' ? '26px' : '64px'};
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: ${props => props.size === 'small' ? '14px' : '46px'};
    height: ${props => props.size === 'small' ? '14px' : '46px'};
    margin: 1px;
    border-radius: 50%;
    border-style: solid;
    border-width: ${props => props.size === 'small' ? '2px' : '5px'};
    border-color: ${props => props.color === 'blue' ? CSS_VARS.color_brand_primary : CSS_VARS.color_white} transparent ${props => props.color === 'blue' ? CSS_VARS.color_brand_primary : CSS_VARS.color_white} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = ({ color, text='state_loading', size='', position='', isInline=false, hasLabel=true }) => {
  return (
    <MainCSS className="psa-loader" color={color} size={size} position={position} isInline={isInline}>
      <div className="lds-dual-ring" />
      {
        hasLabel &&
        <span className="loader-text">{intl(text)}</span>
      }
    </MainCSS>
  )
}

export default Loader
