// cache reducer state is NOT saved in sessionStorage

import {
  UPDATE_CACHE_ACCOUNTS,
  UPDATE_USER_ACCOUNT_TYPES,
  UPDATE_CACHE_BENEFICIARIES,
  UPDATE_CACHE_OFFERS,
  UPDATE_CACHE_INTERESTS,
  UPDATE_CACHE_PERSON,
  UPDATE_CACHE_SPONSOR_EVENTS,
  UPDATE_CACHE_TRANSACTIONS,
  UPDATE_CACHE_TRANSFERS,
  UPDATE_CACHE_TRANSFERS_ACCOUNTS,
  UPDATE_CACHE_VAULTS,
  UPDATE_CACHE_DOCS,
  UPDATE_ACCOUNT_TERM_OFFERS,
  UPDATE_CAT_FOLDER_ID,
  UPDATE_CAT_SIGN_DATE,
  UPDATE_CACHE_RL_DETAILS,
  UPDATE_MINOR_ACCOUNT_STEP,
  UPDATE_MINOR_ACCOUNT_METHOD,
  UPDATE_MINOR_ACCOUNT_FORM_1,
  UPDATE_MINOR_ACCOUNT_FORM_2,
  UPDATE_MINOR_ACCOUNT_FORM_3,
  UPDATE_MINOR_ACCOUNT_FORM_4,
  UPDATE_MINOR_ACCOUNT_FOLDER_ID,
  UPDATE_BO_MESSAGE,
  UPDATE_BO_MESSAGE_PRODUCT,
  UPDATE_BO_SETTINGS,
  UPDATE_OFFER_AVAILABILITY,
  UPDATE_SPONSORSHIP_OFFER_AVAILABILITY,
  UPDATE_SPONSORSHIP_EVENTS_AVAILABILITY,
  UPDATE_LOGIN_BG_IMAGE_URL,
  IS_COACH_AVAILABLE_FOR_USER,
  UPDATE_CACHE_NATIONALITIES,
  UPDATE_CACHE_COUNTRIES,
  UPDATE_CACHE_TOWNS,
  UPDATE_CACHE_RL_TOWNS,
  UPDATE_CACHE_PROMO_CODES,
  UPDATE_CACHE_PROFESSIONS,
  UPDATE_CACHE_BUSINESS_SECTORS,
  UPDATE_CACHE_PERSON_KYC,
  UPDATE_KYC_FORM_2,
  UPDATE_KYC_FORM_3,
  UPDATE_CACHE_COACH_SAVING_PROFILE,
  UPDATE_CAT_FLASH_SETTINGS,
  UPDATE_CAT_FLASH_POPUP_SEEN,
  UPDATE_CAT_GREEN_SETTINGS,
  UPDATE_USER_TAN_STATUS,
  UPDATE_TAN_ENROLMENT_STEP,
  UPDATE_USER_TAN_PHONE_NUMBER,
  LOGOUT,
} from '../actions'

const getInitialState = () => {
  return {
    _memoize: false,
    isSignEnabled: true,
    isFirstLoad: true,
    accounts: {},
    hasMinorAccounts: false,
    hasProxyAccounts: false,
    interests: {},
    offers: {},
    transactions: {},
    person: null,
    sponsorEvents: [],
    transfers: [],
    transferAccounts: [],
    beneficiaries: [],
    vaults: null,
    docs: null,
    accountTermOffers: [],
    catFolderId: null,
    catSignDate: null,
    RLDetails: {},
    minorAccountStep: 1,
    minorAccountMethod: '',
    minorAccountFormData1: {},
    minorAccountFormData2: {},
    minorAccountFormData3: {},
    minorAccountFormData4: {},
    minorAccountFolderId: '',
    BOmessageLogin: '',
    BOmessageSynthesis: '',
    BOmessageNewTransfer: '',
    BOmessageTransferHistory: '',
    BOmessageBeneficiariesList: '',
    BOmessageMyProfil: '',
    BOmessageMyStatements: '',
    BOmessageRib: '',
    BOmessageMyForms: '',
    BOmessageCommercialDoc: '',
    BOmessageSettings: '',
    BOmessageContact: '',
    BOmessageCAT: '',
    BOmessageLivretMineur: '',
    BOmessageCloture: '',
    BOmessageProductLivretEpargne: '',
    BOmessageProductLivretMineur: '',
    BOmessageProductCAT: '',
    BOSettings: {},
    isOfferAvailable: true,
    isSponsorshipOfferAvailable: true,
    isSponsorshipEventsAvailable: true,
    loginBgImageURL: {
      mobile: null,
      desktop: null,
    },
    isCoachAvailableForUser: false,
    nationalities: [],
    countries: [],
    towns: [],
    RLtowns: [],
    promoCodes: [],
    professions: [],
    businessSectors: [],
    personKyc: {},
    kycForm2: {},
    kycForm3: {},
    coachSavingProfile: '',
    isCatFlashAvailable: false,
    catFlashAuthorizedIds: [],
    catFlashSettings: {},
    hasSeenFlashPopup: false, // in user session
    isCatGreenAvailable: false,
    catGreenSettings: {},
    userTanStatus: {},
    tanEnrolmentStep: 1,
    userTanPhoneNumber: '', // format international without +
    userTanCountry: {}, // object { countryCode: 'fr', dialCode: '33', format: '+.. . .. .. .. ..', name: 'France' }
  }
}

function reducer(state = getInitialState(), action) {
  const newState = { ...state }

  switch (action.type) {
    case UPDATE_CACHE_ACCOUNTS:
      action.accounts.forEach((account) => {
        newState.accounts[account.accountNo] = account
      })
      return newState
    case UPDATE_USER_ACCOUNT_TYPES:
      newState.hasMinorAccounts = action.hasMinorAccounts
      newState.hasProxyAccounts = action.hasProxyAccounts
      return newState

    case UPDATE_CACHE_BENEFICIARIES:
      newState.beneficiaries = action.beneficiaries
      return newState
    case UPDATE_CACHE_OFFERS:
      newState.offers[action.accountNo] = action.offers
      return newState
    case UPDATE_CACHE_INTERESTS:
      newState.interests[action.accountNo] = action.interests
      return newState

    case UPDATE_CACHE_PERSON:
      newState.person = action.person
      newState.isFirstLoad = false
      return newState
    case UPDATE_CACHE_SPONSOR_EVENTS:
      newState.sponsorEvents = action.events
      return newState

    case UPDATE_CACHE_TRANSFERS:
      newState.transfers = action.transfers
      return newState
    case UPDATE_CACHE_TRANSFERS_ACCOUNTS:
      newState.transferAccounts = action.accounts
      return newState
    case UPDATE_CACHE_VAULTS:
      newState.vaults = action.vaults
      return newState
    case UPDATE_CACHE_DOCS:
      newState.docs = action.docs
      return newState
    case UPDATE_CACHE_TRANSACTIONS:
      newState.transactions[action.accountNo] = action.transactions
      return newState

    case UPDATE_ACCOUNT_TERM_OFFERS:
      newState.accountTermOffers = action.accountTermOffers
      return newState
    case UPDATE_CAT_FOLDER_ID:
      newState.catFolderId = action.catFolderId
      return newState
    case UPDATE_CAT_SIGN_DATE:
      newState.catSignDate = action.catSignDate
      return newState

    case UPDATE_CACHE_RL_DETAILS:
      newState.RLDetails = action.RLDetails
      return newState

    case UPDATE_MINOR_ACCOUNT_STEP:
      newState.minorAccountStep = action.step
      return newState
    case UPDATE_MINOR_ACCOUNT_METHOD:
      newState.minorAccountMethod = action.method
      return newState
    case UPDATE_MINOR_ACCOUNT_FORM_1:
      newState.minorAccountFormData1 = action.formData
      return newState
    case UPDATE_MINOR_ACCOUNT_FORM_2:
      newState.minorAccountFormData2 = action.formData
      return newState
    case UPDATE_MINOR_ACCOUNT_FORM_3:
      newState.minorAccountFormData3 = action.formData
      return newState
    case UPDATE_MINOR_ACCOUNT_FORM_4:
      newState.minorAccountFormData4 = action.formData
      return newState
    case UPDATE_MINOR_ACCOUNT_FOLDER_ID:
      newState.minorAccountFolderId = action.id
      return newState

    case UPDATE_BO_MESSAGE:
      newState[`BOmessage${action.payload.page}`] = action.payload.message
      return newState
    case UPDATE_BO_MESSAGE_PRODUCT:
      newState[`BOmessageProduct${action.payload.product}`] = action.payload.message
      return newState
    case UPDATE_BO_SETTINGS:
      newState.BOSettings = action.payload
      return newState

    case UPDATE_OFFER_AVAILABILITY:
      return { ...state, isOfferAvailable: action.isAvailable }
    case UPDATE_SPONSORSHIP_OFFER_AVAILABILITY:
      return { ...state, isSponsorshipOfferAvailable: action.isAvailable }
    case UPDATE_SPONSORSHIP_EVENTS_AVAILABILITY:
      return { ...state, isSponsorshipEventsAvailable: action.isAvailable }
    case UPDATE_LOGIN_BG_IMAGE_URL:
      return { ...state, loginBgImageURL: action.loginBgImageURL }

    case IS_COACH_AVAILABLE_FOR_USER:
      newState.isCoachAvailableForUser = action.isCoachAvailableForUser
      return newState

    case UPDATE_CACHE_NATIONALITIES:
      newState.nationalities = action.nationalities
      return newState
    case UPDATE_CACHE_COUNTRIES:
      newState.countries = action.countries
      return newState
    case UPDATE_CACHE_TOWNS:
      newState.towns = action.towns
      return newState
    case UPDATE_CACHE_RL_TOWNS:
      newState.RLtowns = action.RLtowns
      return newState

    case UPDATE_CACHE_PROMO_CODES:
      newState.promoCodes = action.codes
      return newState

    case UPDATE_CACHE_PROFESSIONS:
      newState.professions = action.professions
      return newState
    case UPDATE_CACHE_BUSINESS_SECTORS:
      newState.businessSectors = action.sectors
      return newState
    case UPDATE_CACHE_PERSON_KYC:
      newState.personKyc = action.data
      return newState
    case UPDATE_KYC_FORM_2:
      newState.kycForm2 = action.formData
      return newState
    case UPDATE_KYC_FORM_3:
      newState.kycForm3 = action.formData
      return newState

    case UPDATE_CACHE_COACH_SAVING_PROFILE:
      newState.coachSavingProfile = action.profile
      return newState

    case UPDATE_CAT_FLASH_SETTINGS:
      newState.catFlashSettings = action.settings
      newState.isCatFlashAvailable = action.settings.isEnabled
      newState.catFlashAuthorizedIds = action.settings.userIds
      return newState

    case UPDATE_CAT_FLASH_POPUP_SEEN:
      newState.hasSeenFlashPopup = action.hasSeenFlashPopup
      return newState

    case UPDATE_CAT_GREEN_SETTINGS:
      newState.catGreenSettings = action.settings
      newState.isCatGreenAvailable = action.settings.isEnabled
      return newState

    case UPDATE_USER_TAN_STATUS:
      newState.userTanStatus = action.status
      return newState
    case UPDATE_TAN_ENROLMENT_STEP:
      newState.tanEnrolmentStep = action.step
      return newState
    case UPDATE_USER_TAN_PHONE_NUMBER:
      newState.userTanPhoneNumber = action.phoneNumber
      newState.userTanCountry = action.country
      return newState

    case LOGOUT:
      return getInitialState()

    default:
      return newState
  }
}

export default [reducer, getInitialState()]
