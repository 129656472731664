import { Component } from 'react'
import { logout } from 'services/store/actions'
import utils from 'services/utils'
import CopartisRequester from 'services/api/CopartisRequester'

class Logout extends Component {
  componentDidMount() {
    new CopartisRequester().logout()
    utils.app.remove('session')
    logout()
  }

  render() {
    return null
  }
}

export default Logout
