import { connect } from 'services/store'
import intl from 'services/intl'

const LostPasswordStep1 = ({ goToStep }) => {
  return (
    <div className='stepComponent'>
      <div className='stepComponent-wrapper'>
        <div className='stepComponent-content'>
          <h2 className='stepComponent-title'>{intl`forgotten_password_step_1_title`}</h2>
          <p>{intl`forgotten_password_step_1_txt_1`}</p>
          <div className='stepComponent-actions step1'>
            <button
              type='button'
              className='stepComponent-action stepComponent-action-primary'
              onClick={() => {
                goToStep(2)
              }}>
              {intl`forgotten_password_step_1_cta_1`}
            </button>
          </div>
          <p className='stepComponent-separator'>{intl`forgotten_password_step_1_txt_2`}</p>
          <p>{intl`forgotten_password_step_1_txt_3`}</p>
          <p className='stepComponent-phone'>{intl`forgotten_password_step_1_phone_number`}</p>
          <p className='stepComponent-mentions'>{intl`forgotten_password_step_1_txt_4`}</p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ view: { goToStep } }) => {
  return {
    goToStep,
  }
}

export default connect(mapStateToProps)(LostPasswordStep1)
