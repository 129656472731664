import * as React from 'react'
import styled, { CSS_VARS } from 'services/styled'
import { ReactComponent as SuccessIcon } from 'assets/svg/success.svg'
import { ReactComponent as ErrorIcon } from 'assets/svg/error.svg'
import { ReactComponent as TanSuccessIcon } from 'assets/svg/success-tan.svg'
import { ReactComponent as TanErrorIcon } from 'assets/svg/error-tan.svg'

const MainCSS = styled.div`
  position: relative;
  display: block;
  margin: 50px auto;
  text-align: center;
  > .Notification-content {
    margin-bottom: 50px;
    padding: 50px;
    background-color: ${CSS_VARS.color_white};
    > h2 {
      margin-top: 20px;
    }

    > svg {
      width: 70px;
      height: 70px;
    }

    > hr {
      margin: 40px auto;
      width: 50px;
      border: 1px ${CSS_VARS.color_black} solid;
    }
  }
`

function Notification(props) {
  let icon
  const { title, type, children, buttons, style } = props

  if (type === 'success') {
    if (style === 'tan') {
      icon = <TanSuccessIcon />
    } else {
      icon = <SuccessIcon />
    }
  } else if (type === 'warning' || type === 'error') {
    if (style === 'tan') {
      icon = <TanErrorIcon />
    } else {
      icon = <ErrorIcon />
    }
  }

  return (
    <MainCSS className='Notification'>
      <div className={`Notification-content ${style}`}>
        {icon}
        {title && (
          <>
            <h2 className='psa-headboard'>{title}</h2>
            {children && <hr />}
          </>
        )}
        {children}
      </div>
    </MainCSS>
  )
}

export default Notification
