import { lazy } from 'react'
import { routePermissions } from 'hocs'
import Login from './login/LoginContainer'
import LostPassword from './lostPassword/LostPasswordContainer'
import Logout from './logout/Logout'
import { _APP_PATHS, ROUTES_CONF } from 'services/constants'

const routes = [
  /* public page Login */
  {
    name: 'login',
    path: _APP_PATHS.login,
    showHeaderBackground: false,
    component: routePermissions('public', 'login_text_2', Login),
  },
  {
    name: 'lostPassword',
    path: _APP_PATHS.lostPassword,
    showHeaderBackground: false,
    component: routePermissions('public', 'forgotten_password_step_1_cta_1', LostPassword),
  },
  /* private page KYC */
  {
    name: 'kyc',
    path: _APP_PATHS.kyc,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'public',
      'kyc_title',
      lazy(() => import('./kyc/KycContainer'))
    ),
  },
  /* private page Home page */
  {
    ...ROUTES_CONF.menu_home,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_home',
      lazy(() => import('./home/HomeContainer'))
    ),
  },
  /* private pages Accounts */
  {
    ...ROUTES_CONF.menu_accounts_my_accounts,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_home',
      lazy(() => import('./home/HomeContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_accounts_minor_accounts,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_home',
      lazy(() => import('./home/HomeContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_accounts_proxy_accounts,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_home',
      lazy(() => import('./home/HomeContainer'))
    ),
  },
  /* private pages Transfers */
  {
    ...ROUTES_CONF.menu_transfers_link_1,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'transfer_title',
      lazy(() => import('./transfer/TransferContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_transfers_link_2,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_transfers_link_2',
      lazy(() => import('./transferHistory/TransferHistoryContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_transfers_link_3,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'recipient_list_title',
      lazy(() => import('./recipient/RecipientContainer'))
    ),
  },
  /* private pages Offers */
  {
    ...ROUTES_CONF.menu_my_offers_link_1,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'subscription_CAT_step1_title',
      lazy(() => import('./openTermAccount/OpenTermAccountContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_open_account_callback,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'subscription_CAT_flash_step1_title',
      lazy(() => import('./openTermAccount/OpenTermAccountCallback'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_offers_cat_flash,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_offers_cat_flash',
      lazy(() => import('./openFlashTermAccount/OpenFlashTermAccountContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_open_flash_account_callback,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'subscription_CAT_step1_title',
      lazy(() => import('./openFlashTermAccount/OpenFlashTermAccountCallback'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_offers_cat_green,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'subscription_CAT_green_step1_title',
      lazy(() => import('./openGreenTermAccount/OpenGreenTermAccountContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_open_green_account_callback,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'subscription_CAT_green_step1_title',
      lazy(() => import('./openGreenTermAccount/OpenGreenTermAccountCallback'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_offers_link_2,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_offers_mineur',
      lazy(() => import('./openMinorAccount/OpenMinorAccountContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_open_minor_account_callback,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_offers_mineur',
      lazy(() => import('./openMinorAccount/OpenMinorAccountCallback'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_offers_link_4,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'fidelity_title',
      lazy(() => import('./fidelity/FidelityContainer'))
    ),
  },
  /* private pages Sponsorship */
  {
    ...ROUTES_CONF.menu_sponsorship_offer,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'sponsorship_offer_page_title',
      lazy(() => import('./sponsorshipOffer/sponsorshipOfferContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_sponsorship_events,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'sponsorship_events_page_title',
      lazy(() => import('./sponsorshipEvents/sponsorshipEventsContainer'))
    ),
  },
  /* private pages Coach */
  {
    ...ROUTES_CONF.menu_coach,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'coach_step_1_title_1',
      lazy(() => import('./coach/CoachContainer'))
    ),
  },
  /* private pages statements & contracts */
  {
    ...ROUTES_CONF.menu_my_space_contracts_link_2_web,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'my_space_contracts_title',
      lazy(() => import('./myContracts/MyContractsContainer'))
    ),
  },
  /* private pages My Space */
  {
    ...ROUTES_CONF.menu_my_space_infos_link_1,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'my_space_infos_title',
      lazy(() => import('./profile/ProfileContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_space_rib_link_3,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'my_space_rib_title',
      lazy(() => import('./myRib/MyRibContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_space_forms_link_5,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'my_space_forms_title',
      lazy(() => import('./myForms/MyFormsContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_my_space_closure,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'closure_header_title',
      lazy(() => import('./closure/ClosureContainer'))
    ),
  },
  /* private pages Security */
  {
    ...ROUTES_CONF.menu_security_password,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'params_password_title',
      lazy(() => import('./passwordSetting/PasswordSettingContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_tan_enrol,
    showHeaderBackground: false,
    exact: true,
    component: routePermissions(
      'both',
      'tan_enrolment_title',
      lazy(() => import('./tanEnrolment/TanEnrolmentContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_tan_manage,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'tan_manage_title',
      lazy(() => import('./tanManagement/TanManagementContainer'))
    ),
  },
  /* private page Contact */
  {
    ...ROUTES_CONF.menu_contact,
    showHeaderBackground: true,
    component: routePermissions(
      'both',
      'menu_contact',
      lazy(() => import('./contact/ContactContainer'))
    ),
  },
  {
    ...ROUTES_CONF.menu_accessibility,
    showHeaderBackground: true,
    component: routePermissions(
      'both',
      'menu_accessibility',
      lazy(() => import('./accessibility/AccessibilityView.js'))
    ),
  },
  {
    ...ROUTES_CONF.menu_legal_mentions,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_legal_mentions',
      lazy(() => import('./infos-legal/InfosLegalView'))
    ),
  },
  {
    ...ROUTES_CONF.menu_cgu,
    showHeaderBackground: true,
    exact: true,
    component: routePermissions(
      'private',
      'menu_cgu',
      lazy(() => import('./cgu/CguView'))
    ),
  },
  {
    ...ROUTES_CONF.menu_logout,
    showHeaderBackground: false,
    exact: true,
    component: routePermissions('private', '', Logout),
  },
  {
    name: 'resetPassword',
    path: _APP_PATHS.resetPassword,
    showHeaderBackground: false,
    component: routePermissions(
      'public',
      'modify_password_title',
      lazy(() => import('./resetPassword/ResetPasswordContainer'))
    ),
  },
]

export default routes
