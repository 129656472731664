import React from 'react'
import PdfFormPageTemplate from './PdfFormPageTemplate'

const PdfFormChangeFiscalAddress = ({
  title = 'MR',
  lastName = '',
  birthName = '',
  firstName = '',
  clientRef = '',
  accountNo = '',
}) => {
  const renderPage1Content = () => {
    return (
      <React.Fragment>
        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Identification / Situation</h2>
          <div className='pdf-section-content'>
            <div className='pdf-checkboxes-inline'>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MME'} />
                <span className='case' />
                <label>Mme</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MLLE'} />
                <span className='case' />
                <label>Mlle</label>
              </div>
              <div className='pdf-checkbox'>
                <input type='checkbox' defaultChecked={title === 'MR'} />
                <span className='case' />
                <label>M</label>
              </div>
            </div>
            <div className={`pdf-form-line ${lastName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;: <span>{lastName}</span>
            </div>
            <div className={`pdf-form-line ${birthName !== '' ? 'completed' : ''}`}>
              Nom&nbsp;de&nbsp;naissance&nbsp;: <span>{birthName}</span>
            </div>
            <div className={`pdf-form-line ${firstName !== '' ? 'completed' : ''}`}>
              Prénom(s)&nbsp;: <span>{firstName}</span>
            </div>
            <div className={`pdf-form-line ${clientRef !== '' ? 'completed' : ''}`}>
              Référence&nbsp;client&nbsp;: <span>{clientRef}</span>
            </div>
            <div className={`pdf-form-line ${accountNo !== '' ? 'completed' : ''}`}>
              Numéro&nbsp;de&nbsp;compte&nbsp;livret&nbsp;d'épargne&nbsp;Distingo&nbsp;:{' '}
              <span>{accountNo}</span>
            </div>
          </div>
        </section>

        <section className='pdf-section'>
          <h2 className='pdf-section-title'>Modification de transfert de domicile fiscal</h2>
          <div className='pdf-section-content'>
            <p>
              Titulaire d'un compte sur Livret d'épargne DISTINGO, je souhaite vous notifier mon
              transfert de domicile fiscal actuellement&nbsp;:
            </p>
            <br />
            <div className='pdf-form-line'>
              Adresse&nbsp;complète&nbsp;de&nbsp;mon&nbsp;ancienne&nbsp;résidence&nbsp;fiscale&nbsp;:{' '}
              <span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <br />
            <p>Vers&nbsp;:</p>
            <br />
            <div className='pdf-form-line'>
              Adresse&nbsp;complète&nbsp;de&nbsp;ma&nbsp;nouvelle&nbsp;résidence&nbsp;fiscale&nbsp;:{' '}
              <span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <br />
            <div className='pdf-form-line'>
              Téléphone&nbsp;fixe&nbsp;: <span></span>
            </div>
            <div className='pdf-form-line'>
              Téléphone&nbsp;portable&nbsp;: <span></span>
            </div>
            <br />
            <div className='pdf-form-line'>
              Si&nbsp;adresse&nbsp;dans&nbsp;un&nbsp;État&nbsp;différent&nbsp;de&nbsp;la&nbsp;France&nbsp;-&nbsp;Numéro&nbsp;d'Identifiant&nbsp;Fiscal&nbsp;(NIF)&nbsp;obligatoire&nbsp;:{' '}
              <span></span>
            </div>
            <div className='pdf-form-line'>
              &nbsp;<span></span>
            </div>
            <br />
          </div>
        </section>
        <section className='pdf-section sign-section'>
          <div className='pdf-sign-date-place'>
            Fait à ......................................... Le ......./......../........
            <br />
            <br />
          </div>
          <table className='pdf-sign-table single'>
            <tbody>
              <tr>
                <td width='50%'>Signature du titulaire du compte</td>
              </tr>
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }

  return (
    <div>
      {/* page 1 */}
      <PdfFormPageTemplate
        title="changement d'adresse / domicile fiscal"
        headerType='change_address'
        isFirstPage
        headerCustomText={
          <React.Fragment>
            <span>
              À renvoyer complété et signé, accompagné d'un justificatif, via la rubrique dediee de
              la messagerie de votre Espace privé,{' '}
              <span className='low'>ou par voie postale, à&nbsp;:</span>
            </span>
            <span className='pdf-bold'>DISTINGO - TSA 84321 - 92563 RUEIL MALMAISON CEDEX</span>
          </React.Fragment>
        }
        formRef='CAF042023'>
        {renderPage1Content()}
      </PdfFormPageTemplate>
    </div>
  )
}

export default PdfFormChangeFiscalAddress
