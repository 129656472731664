import MockAdapter from 'axios-mock-adapter'

import { _API_URLS } from 'services/constants'
import utils from 'services/utils'
import tanMock from './mock'

const mockRequests = (axios) => {
  const mock = new MockAdapter(axios, { onNoMatch: 'throwException' })

  const registerUrl = _API_URLS.postRegisterTanService.replace(/\//g, '\\/')
  const registerRegExp = new RegExp(String.raw`${registerUrl}\/[\S]*$`, 'i')

  const activateUrl = _API_URLS.postActivateTanService.replace(/\//g, '\\/')
  const activateRegExp = new RegExp(String.raw`${activateUrl}\/[\S]*$`, 'i')

  const mockOption = utils.app.get('mockOption', false)

  mock
    .onGet(_API_URLS.getTanStatus)
    .reply(function (config) {
      // `config` is the axios config and contains things like the url
      // return an array in the form of [status, data, headers]
      let phoneNumber = null
      if (mockOption === 'W' || mockOption === 'A') {
        phoneNumber = '33670701218'
      }
      const data = {
        ...tanMock.status,
        status: mockOption,
        phoneNumber,
      }
      return [200, data]
    })
    .onPost(_API_URLS.postRegisterTanPhone)
    .reply(function (config) {
      return [200, tanMock.registerTanPhone]
    })
    .onPost(registerRegExp)
    .reply(function (config) {
      utils.app.set('mockOption', 'W')
      return [200, tanMock.registerTanService]
      /* const error = {
        origin: 'caas',
        rawMessage: 'DENIED_INVALID_MOBILE_TAN_EC1',
        message: 'Le Code de Sécurité fourni est incorrect (il vous reste 3 essais).',
        code: '195',
      }
      return [400, error] */
    })
    .onPost(activateRegExp)
    .reply(function (config) {
      utils.app.set('mockOption', 'A')
      return [200, tanMock.activateTanService]
    })
}

export default mockRequests
