import React, { useEffect, useRef } from 'react'
import styled, { CSS_VARS } from 'services/styled'

const MainCSS = styled.div`
  > .psa-fieldError {
    margin: 5px 0 10px;
    color: ${CSS_VARS.color_error};
    font-size: ${CSS_VARS.size_xs};
    text-align: left;
    text-transform: initial;
  }
  opacity: ${props => (props.isWaiting ? 0.5 : 1)};
`

export default props => {
  const { children, fieldId = performance.now() } = props
  const fieldRef = useRef(null)

  useEffect(() => {
    fieldRef.current
      .querySelectorAll('.floatLabelField input:not(.filled-freezed), .floatLabelField select:not(.filled-freezed), .floatLabelField textarea:not(.filled-freezed)')
      .forEach(input => {
        const onBlur = event => {
          if (event.target.value === '') {
            event.target.classList.remove('filled')
          } else {
            event.target.classList.add('filled')
          }
        }

        input.addEventListener('blur', onBlur, false)

        if (input.value !== '') {
          input.classList.add('filled')
        }

        return () => {
          input.removeEventListener('blur', onBlur)
        }
      })
  }, [])

  return (
    <MainCSS ref={fieldRef} className="field" {...props}>
      {children}
      <p id={`${fieldId}-error`} className="psa-fieldError" />
    </MainCSS>
  )
}
