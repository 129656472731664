import React from 'react'
import styled, { CSS_VARS } from 'services/styled'

import intl from 'services/intl'
import { PageTitle, ContentTop, Content } from 'uic/MainTemplate'

import LostPasswordStep1 from './LostPasswordStep1'
import LostPasswordStep2 from './LostPasswordStep2'
import LostPasswordStep3 from './LostPasswordStep3'

const Bar = styled.div`
  width: 45%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  .barStep {
    flex: 1;
    margin: 0 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${CSS_VARS.color_white};
    opacity: 0.5;
    &.barStep-active {
      opacity: 1;
    }
  }
  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    position: absolute;
    top: 120px;
  }
`
const StepsBar = ({ length, current }) => {
  return (
    <Bar>
      {[...Array(length)].map((item, i) => {
        return <span className={`barStep ${i === current && 'barStep-active'}`} key={i}></span>
      })}
    </Bar>
  )
}

const LostPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 0;
  @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
    height: auto;
    min-height: 95vh;
    padding: 70px 0 20px;
    align-items: center;
  }

  .enrolment-title {
    text-align: center;
    padding: 20px 0 30px;
    color: ${CSS_VARS.color_white};
    font-size: ${CSS_VARS.size_l};
    font-weight: ${CSS_VARS.weight_semi_bold};
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      margin-top: 0;
      padding: 10px 0 0;
      font-weight: ${CSS_VARS.weight_bold};
      font-size: ${CSS_VARS.size_xl};
    }
  }
  .stepComponent {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 380px;
    margin: 0 auto;
    padding-bottom: 50px;
    text-align: center;
    .stepComponent-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;
    }
    .stepComponent-image-wrapper {
      max-width: 150px;
      margin: 50px auto;
      .stepComponent-image {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .stepComponent-content {
      padding: 30px 20px;
      background-color: ${CSS_VARS.color_white};
      p {
        margin: 0;
        font-size: ${CSS_VARS.size_s};
        font-weight: ${CSS_VARS.weight_semi_bold};
        color: ${CSS_VARS.color_brand_primary};
        &.stepComponent-separator {
          margin: 20px 0;
          color: ${CSS_VARS.color_tan};
          text-transform: uppercase;
        }
        &.stepComponent-phone {
          margin-top: 7px;
          font-size: ${CSS_VARS.size_l};
        }
        &.stepComponent-mentions {
          margin-top: 10px;
          font-size: ${CSS_VARS.size_xs};
          font-weight: ${CSS_VARS.weight_regular};
          color: ${CSS_VARS.color_grey};
          font-style: italic;
        }
        &.stepComponent-instructions {
          font-weight: ${CSS_VARS.weight_regular};
        }
      }
    }
    .stepComponent-title {
      margin: 0 0 40px;
      font-size: ${CSS_VARS.size_m};
      font-weight: ${CSS_VARS.weight_bold};
      color: ${CSS_VARS.color_tan};
    }
    .stepComponent-actions {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
        flex-direction: row;
      }
      &.step1 {
        margin: 10px auto;
      }
    }
    .stepComponent-action {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 220px;
      margin-bottom: 12px;
      height: 50px;
      padding: 0 20px;
      border-radius: 25px;
      font-size: ${CSS_VARS.size_xs};
      font-weight: ${CSS_VARS.weight_bold};
      text-transform: uppercase;
      &.stepComponent-action-primary {
        color: ${CSS_VARS.color_white};
        border: 2px solid ${CSS_VARS.color_brand_primary};
        background-color: ${CSS_VARS.color_brand_primary};
      }
      &.stepComponent-action-secondary {
        border: 2px solid ${CSS_VARS.color_white};
        color: ${CSS_VARS.color_white};
      }
      &.stepComponent-action-ternary {
        border: 2px solid ${CSS_VARS.color_white};
        color: ${CSS_VARS.color_brand_primary};
        background-color: ${CSS_VARS.color_white};
      }
      &:disabled {
        cursor: not-allowed;
        border: 2px solid ${CSS_VARS.color_disabled} !important;
        color: ${CSS_VARS.color_disabled} !important;
        background-color: transparent !important;
      }
      @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
        margin-left: 10px;
        margin-right: 10px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .stepComponent-form {
      margin-top: 30px;
      fieldset {
        width: 100%;
        margin: 0 3px;
        border: none;
        .fieldset-legend {
          position: absolute;
          top: -24px;
          left: 0;
        }
      }
      input {
        width: 100%;
        max-width: 350px;
        margin: 0;
        height: 50px;
        line-height: 50px;
        padding: 0 15px;
        border: none;
        border-radius: 0;
        font-size: 16px !important;
      }
      .field.floatLabelField .psa-inputText.psa-inputText-short {
        width: calc(calc(100% - 20px) / 3);
        &:nth-of-type(2) {
          margin: 0 10px;
          & + label {
            left: calc(calc(100% - 20px) / 3 + 18px);
          }
        }
        &:nth-of-type(3) {
          & + label {
            left: calc(calc(100% - 20px) / 3 * 2 + 28px);
          }
        }
      }
      .fieldsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .field.floatLabelField {
        width: 100%;
        max-width: 350px;
        margin: 0 auto 15px;
      }
      .stepComponent-form-error {
        margin: 5px 10px 10px;
        color: ${CSS_VARS.color_error};
        font-size: 12px;
        text-align: left;
      }
      .psa-showPassword {
        position: absolute;
        right: 8px;
        top: 10px;
        > svg {
          width: 20px;
          fill: ${CSS_VARS.color_grey};
        }
        > span {
          font-size: 10px;
        }
      }
      @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
        fieldset {
          margin: 0;
        }
        input {
          max-width: 350px;
        }
        .stepComponent-form-error {
          margin: 0 10px;
        }
      }
    }
    &.step2 {
      .stepComponent-content .stepComponent-mentions {
        text-align: left;
        margin-top: 5px;
        @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
          max-width: 350px;
          margin: 5px auto 0;
        }
      }
    }
    @media only screen and (min-width: ${CSS_VARS.min_desktop_screen}) {
      justify-content: flex-start;
      align-items: center;
      max-width: 600px;
      margin: 50px 0 0;
      padding-bottom: 10px;
      .stepComponent-content {
        margin-bottom: 10px;
      }
    }
  }
`
const LostPasswordView = ({ currentStep }) => {
  const steps = {
    lostPasswordStep1: LostPasswordStep1,
    lostPasswordStep2: LostPasswordStep2,
    lostPasswordStep3: LostPasswordStep3,
  }
  const StepComponent = steps[`lostPasswordStep${currentStep}`]
  const currentDisplayedStep = currentStep - 1

  return (
    <>
      <PageTitle hide />
      <ContentTop hide />
      <Content>
        <div className='col-sm-12 col-md-push-1 col-md-10'>
          <LostPasswordWrapper>
            <StepsBar length={3} current={currentDisplayedStep} />
            <h1 className='enrolment-title'>{intl`forgotten_password_title`}</h1>
            <StepComponent />
          </LostPasswordWrapper>
        </div>
      </Content>
    </>
  )
}

export default LostPasswordView
