import React, { memo, useContext, useReducer } from 'react'
import { each, isUndefined, pickBy, size } from 'lodash-es'
import { Subject } from 'rxjs'
import reducers from './reducers'
import utils from 'services/utils'

const dispatchRegister = []
const storeContext = React.createContext()
const globalState = utils.app.get('globalState') || {}

export function dispatch(action) {
  dispatchRegister.forEach((_dispatch) => _dispatch(action))
}

export function getGlobalState() {
  return Object.assign({}, globalState)
}

export const storeListener = new Subject()

/* Store provider
 * Manages the global state of the app
 */
export function storeProvider(BaseComponent) {
  return function () {
    const memoize = {}

    // Reset the register
    dispatchRegister.splice(0)

    each(reducers, function ([reducer, initialState], name) {
      const [state, dispatch] = useReducer(reducer, globalState[name] || initialState)

      globalState[name] = state
      dispatchRegister.push(dispatch)

      if (state._memoize !== false) {
        memoize[name] = state
      }
    })

    // Make persistant the global state
    utils.app.set('globalState', memoize)

    // Notify store observers
    storeListener.next(globalState)

    return (
      // Always set value property with a new object
      <storeContext.Provider value={Object.assign({}, globalState)}>
        <BaseComponent />
      </storeContext.Provider>
    )
  }
}

/*
 * Store consumer : inspired of redux
 * @param {number} handleMapStateToProps A list of props to map.
 * @param {Boolean} ignoreMissingProps Ignores the number of props expected.
 * @returns {function} Returns HOC.
 */
export function connect(handleMapStateToProps = null, ignoreMissingProps = false) {
  const fromContextProps = (BaseComponent) => (props) => {
    const propsWithContext = Object.assign({}, props, useContext(storeContext))
    const mappedProps = handleMapStateToProps ? handleMapStateToProps(propsWithContext) : {}

    // Ignores the missing props or checks if the whole of data are availables
    if (ignoreMissingProps || !size(pickBy(mappedProps, isUndefined))) {
      const propsWithHandlers = Object.assign({}, mappedProps)

      return <BaseComponent {...propsWithHandlers} />
    } else {
      return null
    }
  }

  return (BaseComponent) => fromContextProps(memo(BaseComponent))
}
