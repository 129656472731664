import React, { useState } from 'react'

import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'
import { Form, Field, FileField, Loader } from 'uic'

const Wrapper = styled.div`
  > form {
    margin: 5px;
    .mentions {
      display: block;
      margin: 30px 0 10px;
      font-style: italic;
      font-size: 12px;
      text-align: center;
    }
    label {
      padding: 5px;
      font-size: 15px;
    }

    .radioWrapper {
      width: 100%;
      margin: 0 auto 10px auto;
      padding: 5px;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-block-start: 0;
      padding-block-end: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      border: none;
      label {
        margin: 0 25px 0 4px;
      }
    }

    .checkboxWrapper {
      width: 100%;
      margin: 0 auto 10px auto;
      padding: 5px;
      input {
        margin: 0 5px;
      }
    }

    .fileUploadWrapper {
      display: flex;
      flex-direction: column;
      margin: 0 auto 10px auto;
    }

    > .submit {
      display: block;
      margin: 20px auto 30px;
      max-width: 240px;
      > button[type='submit'] {
        width: 100%;
        font-size: ${CSS_VARS.size_s};
      }
    }
    .notice {
      font-size: 12px;
      > a {
        color: currentColor;
        text-decoration: underline;
      }
    }
    .hiddenField {
      margin: 0;
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }

  @media (min-width: ${CSS_VARS.min_tablet_screen}) {
    > form {
      margin: 5px 80px;
    }
  }
`

const Selecter = styled.select``

const ContactForm = ({
  children,
  hideDisabledFields = false,
  userId,
  title,
  lastName,
  firstName,
  address,
  options,
  callback,
  submitForm,
  submitLabel,
  maxSizeMo = 1000000,
  maxSizeText = '1 Mo',
  mention = intl('contact_email_form_client_mention_3_bis'),
}) => {
  const [files, setFiles] = useState({
    file1: {
      empty: true,
      order: 1,
      display: 'block',
    },
    file2: {
      empty: true,
      order: 2,
      display: 'none',
    },
    file3: {
      empty: true,
      order: 3,
      display: 'none',
    },
  })
  const [selectedOption, setSelectedOption] = useState('')
  const [isPending, setIsPending] = useState(false)

  const submit = (fields) => {
    setIsPending(true)
    return submitForm(fields).then(() => {
      setIsPending(false)
      callback()
    })
  }

  const onSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleUploadFile = (update) => {
    const { file, name } = update
    const length = Object.keys(files).length
    const empty = !file

    // Clone the state
    let newState = { ...files }
    newState[name].empty = empty
    if (empty === true) {
      newState[name].order += length
    }

    // Reorder the elements and their display
    let orders = []
    let displayNext = true
    Object.keys(newState).map((key) => {
      return orders.push({ ...newState[key], name: key })
    })

    orders.sort(function (a, b) {
      if (a.order < b.order) {
        return -1
      } else if (a.order > b.order) {
        return 1
      }
      return 0
    })

    orders.forEach((file, index) => {
      let display = 'block'
      if (displayNext === false) {
        display = 'none'
      } else if (displayNext === true && file.empty === true) {
        displayNext = false
      }

      newState[file.name] = {
        order: index + 1,
        empty: file.empty,
        display: display,
      }
    })

    // Update the state
    setFiles(newState)
  }

  const disabledField = Boolean(userId)

  return (
    <Wrapper>
      <Form onSubmit={submit}>
        <span className='mentions sr-only'>{intl`login_text_3`}</span>
        <Field
          fieldId='titleClientMr'
          className={disabledField && hideDisabledFields ? 'hiddenField' : ''}>
          <fieldset className='radioWrapper'>
            <legend className='sr-only'>{intl`contact_email_form_legend_1`}</legend>
            <input
              type='radio'
              name='gender'
              id='titleClientMr'
              value='MR'
              defaultChecked={title === 'MR'}
              disabled={disabledField}
            />
            <label htmlFor='titleClientMr'>{intl`contact_email_form_label_1`}</label>
            <input
              type='radio'
              name='gender'
              id='titleClientMme'
              value='MME'
              defaultChecked={title === 'MME'}
              disabled={disabledField}
            />
            <label htmlFor='titleClientMme'>{intl`contact_email_form_label_2`}</label>
          </fieldset>
        </Field>
        <Field
          fieldId='lastName'
          className={
            disabledField && hideDisabledFields
              ? 'hiddenField field floatLabelField'
              : 'field floatLabelField'
          }>
          <input
            className='psa-inputText'
            type='text'
            name='lastName'
            id='lastName'
            defaultValue={lastName}
            data-required
            aria-required='true'
            title={intl`contact_email_form_placeholder_1`}
            readOnly={disabledField}
          />
          <label htmlFor='lastName'>{intl`contact_email_form_placeholder_1`}</label>
        </Field>
        <Field
          fieldId='firstName'
          className={
            disabledField && hideDisabledFields
              ? 'hiddenField field floatLabelField'
              : 'field floatLabelField'
          }>
          <input
            className='psa-inputText'
            type='text'
            name='firstName'
            id='firstName'
            defaultValue={firstName}
            data-required
            aria-required='true'
            title={intl`contact_email_form_placeholder_2`}
            readOnly={disabledField}
          />
          <label htmlFor='firstName'>{intl`contact_email_form_placeholder_2`}</label>
        </Field>
        <Field
          fieldId='emailAddress'
          className={
            disabledField && hideDisabledFields
              ? 'hiddenField field floatLabelField'
              : 'field floatLabelField'
          }>
          <input
            className='psa-inputText'
            type='text'
            name='emailAddress'
            id='emailAddress'
            defaultValue={address}
            data-type='email'
            data-required
            aria-required='true'
            title={intl`contact_email_form_placeholder_3`}
            readOnly={disabledField}
          />
          <label htmlFor='emailAddress'>{intl`contact_email_form_placeholder_3`}</label>
        </Field>
        <Field
          fieldId='clientId'
          className={
            disabledField && hideDisabledFields
              ? 'hiddenField field floatLabelField'
              : 'field floatLabelField'
          }>
          <input
            className='psa-inputText'
            type='text'
            name='clientId'
            id='clientId'
            defaultValue={userId}
            title={intl`contact_email_form_placeholder_4`}
            readOnly={disabledField}
          />
          <label htmlFor='clientId'>{intl`contact_email_form_placeholder_4`}</label>
        </Field>
        <Field fieldId='object' className='field floatLabelField'>
          <Selecter
            onChange={onSelectChange}
            className='psa-select'
            id='object'
            name='object'
            data-required
            aria-required='true'>
            {options.map((item) => {
              if (item.isGroup) {
                return (
                  <optgroup key={item.label} label={item.label}>
                    {item.options.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </optgroup>
                )
              } else {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                )
              }
            })}
          </Selecter>
          <label htmlFor='object'>{intl`contact_email_form_label_4`}</label>
        </Field>
        {selectedOption === 'DROITS_ET_PROTECTION_DES_DONNEES' && (
          <p
            style={{
              fontSize: '12px',
              fontStyle: 'italic',
            }}>{intl`contact_email_form_client_mention_1`}</p>
        )}
        <div className='fileUploadWrapper'>
          {Object.keys(files).map((key) => {
            return (
              <FileField
                key={key}
                name={key}
                onChange={handleUploadFile}
                checkExtension
                authorisedTypes={['image/jpeg', 'image/png', 'application/pdf']}
                label={intl`contact_email_form_label_7`}
                mention={mention}
                style={{ display: files[key].display, order: files[key].order }}
                maxSizeMo={maxSizeMo}
                maxSizeText={maxSizeText}
              />
            )
          })}
        </div>
        <Field fieldId='message' className='field floatLabelField'>
          <textarea
            className='psa-textarea'
            id='message'
            name='message'
            title={intl`contact_email_form_placeholder_5`}
            data-required
            aria-required='true'
          />
          <label htmlFor='message'>{intl`contact_email_form_placeholder_5`}</label>
        </Field>
        <div className='submit'>
          {isPending ? (
            <Loader size='small' isInline hasLabel={false} color={'blue'} position='static' />
          ) : (
            <button className='psa-button isPrimary' type='submit'>
              {submitLabel}
            </button>
          )}
        </div>
        {children}
      </Form>
    </Wrapper>
  )
}

export default ContactForm
