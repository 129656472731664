// view reducer state is NOT saved in sessionStorage
import {
  SET_VIEW_STATE,
  CLEAR_VIEW_STATE,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SHOW_OTP_INPUT,
  HIDE_OTP_INPUT,
} from '../actions'

const getInitialState = () => {
  return {
    _memoize: false,
    _path: null,
    notification: null,
    onModalClose: null,
    otpCodeType: '',
    otpRoute: '',
    otpOperation: '',
    otpRequestBody: null,
    otpCopartisRequester: null,
    otpOnError: null,
    otpOnSuccess: null,
    otpCloseCallback: null,
  }
}

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_VIEW_STATE:
      return { ...state, ...action.state }
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
        onModalClose: action.onModalClose,
      }
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: null,
        onModalClose: null,
      }
    case SHOW_OTP_INPUT:
      return {
        ...state,
        otpCodeType: action.codeType,
        otpRoute: action.route,
        otpOperation: action.operation,
        otpRequestBody: action.data,
        otpCopartisRequester: action.requester,
        otpOnError: action.onError,
        otpOnSuccess: action.onSuccess,
        otpCloseCallback: action.closeCallback,
      }
    case HIDE_OTP_INPUT:
      return {
        ...state,
        otpCodeType: '',
        otpRoute: '',
        otpOperation: '',
        otpRequestBody: null,
        otpCopartisRequester: null,
        otpOnError: null,
        otpOnSuccess: null,
        otpCloseCallback: null,
      }
    case CLEAR_VIEW_STATE:
      return getInitialState()
    default:
      return state
  }
}

export default [reducer, getInitialState()]
