import { dispatch } from './index'

/*
 * action types
 */

// App
/* export const AUTHENTICATE_USER = 'AUTHENTICATE_USER' */

// View
export const SET_VIEW_STATE = 'SET_VIEW_STATE'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SHOW_OTP_INPUT = 'SHOW_OTP_INPUT'
export const HIDE_OTP_INPUT = 'HIDE_OTP_INPUT'
export const CLEAR_VIEW_STATE = 'CLEAR_VIEW_STATE'

// Cache
export const UPDATE_CACHE_ACCOUNTS = 'UPDATE_CACHE_ACCOUNTS'
export const UPDATE_CACHE_OFFERS = 'UPDATE_CACHE_OFFERS'
export const UPDATE_CACHE_INTERESTS = 'UPDATE_CACHE_INTERESTS'

export const UPDATE_CACHE_PERSON = 'UPDATE_CACHE_PERSON'
export const UPDATE_CACHE_SPONSOR_EVENTS = 'UPDATE_CACHE_SPONSOR_EVENTS'

export const UPDATE_CACHE_TRANSACTIONS = 'UPDATE_CACHE_TRANSACTIONS'
export const UPDATE_CACHE_TRANSFERS = 'UPDATE_CACHE_TRANSFERS'
export const UPDATE_CACHE_TRANSFERS_ACCOUNTS = 'UPDATE_CACHE_TRANSFERS_ACCOUNTS'
export const UPDATE_CACHE_BENEFICIARIES = 'UPDATE_CACHE_BENEFICIARIES'
export const UPDATE_CACHE_VAULTS = 'UPDATE_CACHE_VAULTS'
export const UPDATE_CACHE_DOCS = 'UPDATE_CACHE_DOCS'
export const UPDATE_ACCOUNT_TERM_OFFERS = 'UPDATE_ACCOUNT_TERM_OFFERS'
export const UPDATE_CAT_FOLDER_ID = 'UPDATE_CAT_FOLDER_ID'
export const UPDATE_CAT_SIGN_DATE = 'UPDATE_CAT_SIGN_DATE'

export const UPDATE_CACHE_RL_DETAILS = 'UPDATE_CACHE_RL_DETAILS'

export const UPDATE_MINOR_ACCOUNT_STEP = 'UPDATE_MINOR_ACCOUNT_STEP'
export const UPDATE_MINOR_ACCOUNT_METHOD = 'UPDATE_MINOR_ACCOUNT_METHOD'
export const UPDATE_MINOR_ACCOUNT_FORM_1 = 'UPDATE_MINOR_ACCOUNT_FORM_1'
export const UPDATE_MINOR_ACCOUNT_FORM_2 = 'UPDATE_MINOR_ACCOUNT_FORM_2'
export const UPDATE_MINOR_ACCOUNT_FORM_3 = 'UPDATE_MINOR_ACCOUNT_FORM_3'
export const UPDATE_MINOR_ACCOUNT_FORM_4 = 'UPDATE_MINOR_ACCOUNT_FORM_4'
export const UPDATE_MINOR_ACCOUNT_FOLDER_ID = 'UPDATE_MINOR_ACCOUNT_FOLDER_ID'

export const UPDATE_BO_MESSAGE = 'UPDATE_BO_MESSAGE'
export const UPDATE_BO_MESSAGE_PRODUCT = 'UPDATE_BO_MESSAGE_PRODUCT'
export const UPDATE_BO_SETTINGS = 'UPDATE_BO_SETTINGS'

export const UPDATE_LOGIN_BG_IMAGE_URL = 'UPDATE_LOGIN_BG_IMAGE_URL'

export const IS_COACH_AVAILABLE_FOR_USER = 'IS_COACH_AVAILABLE_FOR_USER'

export const UPDATE_CACHE_NATIONALITIES = 'UPDATE_CACHE_NATIONALITIES'
export const UPDATE_CACHE_COUNTRIES = 'UPDATE_CACHE_COUNTRIES'
export const UPDATE_CACHE_TOWNS = 'UPDATE_CACHE_TOWNS'
export const UPDATE_CACHE_RL_TOWNS = 'UPDATE_CACHE_RL_TOWNS'
export const UPDATE_CACHE_PROMO_CODES = 'UPDATE_CACHE_PROMO_CODES'

export const UPDATE_CACHE_PROFESSIONS = 'UPDATE_CACHE_PROFESSIONS'
export const UPDATE_CACHE_BUSINESS_SECTORS = 'UPDATE_CACHE_BUSINESS_SECTORS'
export const UPDATE_CACHE_PERSON_KYC = 'UPDATE_CACHE_PERSON_KYC'
export const UPDATE_KYC_FORM_2 = 'UPDATE_KYC_FORM_2'
export const UPDATE_KYC_FORM_3 = 'UPDATE_KYC_FORM_3'

export const UPDATE_CACHE_SHOW_COACH_MODAL = 'UPDATE_CACHE_SHOW_COACH_MODAL'
export const UPDATE_CACHE_COACH_SAVING_PROFILE = 'UPDATE_CACHE_COACH_SAVING_PROFILE'

export const UPDATE_OFFER_AVAILABILITY = 'UPDATE_OFFER_AVAILABILITY'
export const UPDATE_SPONSORSHIP_OFFER_AVAILABILITY = 'UPDATE_SPONSORSHIP_OFFER_AVAILABILITY'
export const UPDATE_SPONSORSHIP_EVENTS_AVAILABILITY = 'UPDATE_SPONSORSHIP_EVENTS_AVAILABILITY'

export const UPDATE_CAT_FLASH_SETTINGS = 'UPDATE_CAT_FLASH_SETTINGS'
export const UPDATE_CAT_FLASH_POPUP_SEEN = 'UPDATE_CAT_FLASH_POPUP_SEEN'

export const UPDATE_CAT_GREEN_SETTINGS = 'UPDATE_CAT_GREEN_SETTINGS'

export const UPDATE_USER_ACCOUNT_TYPES = 'UPDATE_USER_ACCOUNT_TYPES'

export const UPDATE_USER_TAN_STATUS = 'UPDATE_USER_TAN_STATUS'
export const UPDATE_TAN_ENROLMENT_STEP = 'UPDATE_TAN_ENROLMENT_STEP'
export const UPDATE_USER_TAN_PHONE_NUMBER = 'UPDATE_USER_TAN_PHONE_NUMBER'

// Common
export const LOGOUT = 'LOGOUT'

/*
 * action creators
 */

/* export function authenticateUser(token) {
  dispatch({ type: AUTHENTICATE_USER, token })
} */

export function showNotification(notification = null, onModalClose = null) {
  dispatch({ type: SHOW_NOTIFICATION, notification, onModalClose })
}

export function hideNotification() {
  dispatch({ type: HIDE_NOTIFICATION })
}

export function showOtpInput({
  codeType,
  route,
  operation,
  data,
  requester,
  onError,
  onSuccess,
  closeCallback,
}) {
  dispatch({
    type: SHOW_OTP_INPUT,
    codeType,
    route,
    operation,
    data,
    requester,
    onError,
    onSuccess,
    closeCallback,
  })
}

export function hideOtpInput() {
  dispatch({ type: HIDE_OTP_INPUT })
}

export function setState(state) {
  dispatch({ type: SET_VIEW_STATE, state })
}

export function clearViewState() {
  dispatch({ type: CLEAR_VIEW_STATE })
}

export function updateCacheAccounts(accounts) {
  dispatch({ type: UPDATE_CACHE_ACCOUNTS, accounts })
}

export function updateCacheOffers(offers) {
  dispatch({ type: UPDATE_CACHE_OFFERS, ...offers })
}

export function updateCacheInterests(data) {
  dispatch({ type: UPDATE_CACHE_INTERESTS, ...data })
}

export function updateCachePerson(person) {
  dispatch({ type: UPDATE_CACHE_PERSON, person })
}

export function updateCacheSponsorEvents(events) {
  dispatch({ type: UPDATE_CACHE_SPONSOR_EVENTS, events })
}

export function updateCacheTransactions(data) {
  dispatch({ type: UPDATE_CACHE_TRANSACTIONS, ...data })
}

export function updateCacheTransfers(transfers) {
  dispatch({ type: UPDATE_CACHE_TRANSFERS, transfers })
}

export function updateCacheTransferAccounts(accounts) {
  dispatch({ type: UPDATE_CACHE_TRANSFERS_ACCOUNTS, accounts })
}

export function updateCacheBeneficiaries(beneficiaries) {
  dispatch({ type: UPDATE_CACHE_BENEFICIARIES, beneficiaries })
}

export function updateCacheVaults(vaults) {
  dispatch({ type: UPDATE_CACHE_VAULTS, vaults })
}

export function updateCacheDocs(docs) {
  dispatch({ type: UPDATE_CACHE_DOCS, docs })
}

export function updateAccountTermOffers(accountTermOffers) {
  dispatch({ type: UPDATE_ACCOUNT_TERM_OFFERS, accountTermOffers })
}

export function updateCATFolderId(catFolderId) {
  dispatch({ type: UPDATE_CAT_FOLDER_ID, catFolderId })
}

export function updateCATSignDate(catSignDate) {
  dispatch({ type: UPDATE_CAT_SIGN_DATE, catSignDate })
}

export function updateCacheRLDetails(RLDetails) {
  dispatch({ type: UPDATE_CACHE_RL_DETAILS, RLDetails })
}

export function updateMinorAccountStep(step) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_STEP, step })
}

export function updateMinorAccountMethod(method) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_METHOD, method })
}

export function updateMinorAccountForm1(formData) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_FORM_1, formData })
}

export function updateMinorAccountForm2(formData) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_FORM_2, formData })
}

export function updateMinorAccountForm3(formData) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_FORM_3, formData })
}

export function updateMinorAccountForm4(formData) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_FORM_4, formData })
}

export function updateMinorAccountFolderId(id) {
  dispatch({ type: UPDATE_MINOR_ACCOUNT_FOLDER_ID, id })
}

export function updateBOMessage(page, message) {
  dispatch({
    type: UPDATE_BO_MESSAGE,
    payload: { page, message },
  })
}

export function updateBOMessageProduct(product, message) {
  dispatch({
    type: UPDATE_BO_MESSAGE_PRODUCT,
    payload: { product, message },
  })
}

export function updateBOSettings(settings) {
  dispatch({
    type: UPDATE_BO_SETTINGS,
    payload: settings,
  })
}

// define if user can access to coach (see in misc)
export function isCoachAvailableForUser(bool) {
  dispatch({ type: IS_COACH_AVAILABLE_FOR_USER, isCoachAvailableForUser: bool })
}

export function updateCacheNationalities(nationalities) {
  dispatch({ type: UPDATE_CACHE_NATIONALITIES, nationalities })
}

export function updateCacheCountries(countries) {
  dispatch({ type: UPDATE_CACHE_COUNTRIES, countries })
}

export function updateCacheTowns(towns) {
  dispatch({ type: UPDATE_CACHE_TOWNS, towns })
}

export function updateCacheRLTowns(RLtowns) {
  dispatch({ type: UPDATE_CACHE_RL_TOWNS, RLtowns })
}

export function updateCachePromoCodes(codes) {
  dispatch({ type: UPDATE_CACHE_PROMO_CODES, codes })
}

export function updateCacheProfessions(professions) {
  dispatch({ type: UPDATE_CACHE_PROFESSIONS, professions })
}

export function updateCacheBusinessSectors(sectors) {
  dispatch({ type: UPDATE_CACHE_BUSINESS_SECTORS, sectors })
}

export function updateCachePersonKyc(data) {
  dispatch({ type: UPDATE_CACHE_PERSON_KYC, data })
}

export function updateKycForm2(formData) {
  dispatch({ type: UPDATE_KYC_FORM_2, formData })
}

export function updateKycForm3(formData) {
  dispatch({ type: UPDATE_KYC_FORM_3, formData })
}

export function updateCacheCoachSavingProfile(profile) {
  dispatch({ type: UPDATE_CACHE_COACH_SAVING_PROFILE, profile })
}

export function updateOfferAvailability(bool) {
  dispatch({ type: UPDATE_OFFER_AVAILABILITY, isAvailable: bool })
}

export function updateSponsorshipOfferAvailability(bool) {
  dispatch({ type: UPDATE_SPONSORSHIP_OFFER_AVAILABILITY, isAvailable: bool })
}

export function updateSponsorshipEventsAvailability(bool) {
  dispatch({ type: UPDATE_SPONSORSHIP_EVENTS_AVAILABILITY, isAvailable: bool })
}

export function updateCatFlashSettings(settings) {
  dispatch({ type: UPDATE_CAT_FLASH_SETTINGS, settings })
}

export function updateCatFlashPopupSeen(bool) {
  dispatch({ type: UPDATE_CAT_FLASH_POPUP_SEEN, hasSeenFlashPopup: bool })
}

export function updateCatGreenSettings(settings) {
  dispatch({ type: UPDATE_CAT_GREEN_SETTINGS, settings })
}

export function updateUserAccountTypes({ hasMinorAccounts, hasProxyAccounts }) {
  dispatch({ type: UPDATE_USER_ACCOUNT_TYPES, hasMinorAccounts, hasProxyAccounts })
}

export function updateLoginBgImageURL(loginBgImageURL) {
  dispatch({ type: UPDATE_LOGIN_BG_IMAGE_URL, loginBgImageURL })
}

export function updateUserTanStatus(status) {
  dispatch({ type: UPDATE_USER_TAN_STATUS, status })
}

export function updateTanEnrolmentStep(step) {
  dispatch({ type: UPDATE_TAN_ENROLMENT_STEP, step })
}

export function updateUserTanPhoneNumber(phoneNumber, country) {
  dispatch({ type: UPDATE_USER_TAN_PHONE_NUMBER, phoneNumber, country })
}

export function logout() {
  dispatch({ type: LOGOUT })
}
