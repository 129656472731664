import React from 'react'
import styled, { CSS_VARS } from 'services/styled'
import intl from 'services/intl'
import Wave from 'assets/svg/wave.svg'

const MainCSS = styled.section`
  padding: 2rem;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  .NewDistingoBankAppModal {
    &-title {
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      color: #4c4b74;
    }
    &-description {
      margin-top: 4rem;
      margin-bottom: 5rem;
      font-size: 15px;
      text-align: justify;
      color: #4c4b74;
    }
    &-buttons {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 2rem;
      flex-direction: column;
      height: 140px;
      img {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
      }
      .psa-button {
        width: 75%;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 12px;
        text-transform: none;
        &:after {
          display: none;
        }
      }
      a {
        cursor: pointer;
        text-decoration: none;
      }
      .isPrimary {
        background-color: #6260ef;
        color: ${CSS_VARS.color_white};
        font-weight: 400;
      }
      .isSecondary {
        color: #6761eb;
      }
    }
  }

  @media only screen and (min-width: 414px) {
    padding: 4rem 4rem 3rem;
  }
`

const NewDistingoBankAppModal = ({ tryNowUrl, onTryNow, onLater }) => {
  return (
    <MainCSS className='NewDistingoBankAppModal'>
      <div className='NewDistingoBankAppModal-title'>
        <span>{intl`my_account_modal_new_app_title`}</span>
      </div>
      <p className='NewDistingoBankAppModal-description'>
        {intl`my_account_modal_new_app_text_1`}
        <strong>{intl`my_account_modal_new_app_text_2`}</strong>.<br></br>
        {intl`my_account_modal_new_app_text_3`}
      </p>
      <div className='NewDistingoBankAppModal-buttons'>
        <img src={Wave} alt='' />
        <a
          className='psa-button isSecondary'
          onClick={onTryNow}
          href={tryNowUrl}
          target='_blank'
          rel='noopener noreferrer'>{intl`my_account_modal_new_app_cta_1`}</a>
        <button
          className='psa-button isPrimary'
          onClick={onLater}
          type='button'>{intl`my_account_modal_new_app_cta_2`}</button>
      </div>
    </MainCSS>
  )
}

export default NewDistingoBankAppModal
