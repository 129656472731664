import React, { useState, useEffect } from 'react'
import styled, { CSS_VARS } from 'services/styled'
import { Field } from 'uic'

const MainCSS = styled.div`
  padding: 15px;
  max-width: 250px;
  width: 100%;
  background: ${(props) => props.bgColor};
  color: ${CSS_VARS.color_white};
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  > div:first-child {
    position: relative;
    display: inline-block;

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px ${(props) => props.bgColor} inset;
      -webkit-text-fill-color: ${(props) => props.thecolor};
    }

    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px ${(props) => props.bgColor} inset;
      -webkit-text-fill-color: ${(props) => props.thecolor};
    }
    > input {
      display: inline-block;
      width: 100%;
      max-width: 250px;
      margin: 20px 0;
      padding: 0 25px 10px;
      height: 40px;
      line-height: 30px;
      vertical-align: top;
      text-align: center;
      background: ${(props) => props.bgColor};
      color: ${(props) => props.thecolor};
      font-weight: ${CSS_VARS.weight_bold};
      font-size: 40px;
      border: 0;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: ${(props) =>
        props.bgColor === 'transparent' ? CSS_VARS.color_disabled : CSS_VARS.color_white};
      ::placeholder {
        color: ${(props) => props.thecolor};
        opacity: 0.8;
      }
    }
    > span {
      position: absolute;
      right: 0;
      top: 15px;
      font-size: 30px;
      font-weight: ${CSS_VARS.weight_medium};
      color: ${(props) => props.thecolor};
    }
  }

  @media (min-width: ${CSS_VARS.min_tablet_screen}) {
    max-width: 350px;
    font-size: 18px;

    > div:first-child {
      > input {
        max-width: 200px;
      }
    }
  }
`

export default ({
  label,
  onChange,
  amount,
  isIntegerOnly = false,
  bgColor = CSS_VARS.color_brand_secondary,
  thecolor = CSS_VARS.color_brand_primary,
}) => {
  const [blockAmount, setBlockAmount] = useState(amount || '')

  useEffect(() => {
    if (!amount) {
      setBlockAmount('')
    }
  }, [amount])

  const handleChange = function (event, onChange) {
    let transferAmount
    const val = event.target.value
    let value = isIntegerOnly
      ? val.trim().replace(/[^0-9]/g, '')
      : val.trim().replace(/[^0-9,]/g, '')

    value = value.split(',')

    if (value.length > 1) {
      value[1] = value[1].substring(0, 2)
      // Do not use join to prevent multiple ','
      transferAmount = value[0] + ',' + value[1]
    } else {
      transferAmount = value[0]
    }

    event.target.value = transferAmount

    onChange(transferAmount)
    setBlockAmount(transferAmount)
  }

  return (
    <MainCSS className='AmountBlock' bgColor={bgColor} thecolor={thecolor}>
      {label}
      <Field>
        <input
          className='psa-inputText'
          type='text'
          placeholder='0'
          name='value'
          value={blockAmount}
          onChange={(event) => handleChange.apply(null, [event, onChange])}
        />
        <span>€</span>
      </Field>
    </MainCSS>
  )
}
