// Show a placeholder if the page is disabled from Back-office

import React, { useState, useEffect } from 'react'
import { Loader } from 'uic'
import { Content, PageTitle } from 'uic/MainTemplate'
import BORequester from 'services/api/BORequester'
import intl from 'services/intl'
import utils from 'services/utils'
import styled, { CSS_VARS } from 'services/styled'

const MainCSS = styled.div`
  padding: 20px;
  background-color: ${CSS_VARS.color_white};
`

function placeholderHoc(pageName, pageTitle, Component) {
  return function Placeholder() {
    const [isEnabledPage, setPageStatus] = useState(null)

    const componenDidMount = () => {
      ;(async () => {
        const newBORequester = new BORequester()
        const settings = await newBORequester.getSettings()

        if (settings.error || !Object.keys(settings.data).length) {
          setPageStatus(true)
        } else {
          const pagesStatus = settings.data.pages_setting || []
          const page = pagesStatus.filter((page) => page.slug === pageName)?.[0]
          if (page) {
            setPageStatus(page.enabled)
          } else {
            setPageStatus(true)
          }
        }
      })()
    }

    useEffect(componenDidMount, [])

    if (isEnabledPage === null) {
      return <Loader color={'blue'} />
    } else if (isEnabledPage) {
      return <Component />
    } else {
      return (
        <>
          <PageTitle>{intl(pageTitle)}</PageTitle>
          <Content>
            <div className='col-sm-12 col-md-9 innerContent'>
              <MainCSS>{intl`disabled_screen_message`}</MainCSS>
            </div>
          </Content>
        </>
      )
    }
  }
}

export default placeholderHoc
